import { useRef } from "react";

import ios from "../assets/ios.png";
import android from "../assets/android.png";
import promotion from "../assets/download.png";
import MoreButton from "../components/MoreButton";

import slider1 from "../assets/slider-1.png";
import slider2 from "../assets/slider-2.png";
import slider3 from "../assets/slider-3.png";
import slider4 from "../assets/slider-4.png";

import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import FeatureSlider from "../components/FeatureSlider";

const Download = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="mt-[100px]">
      <div className="flex flex-col px-4 py-6 sm:py-6 sm:px-[17vw] justify-between items-center sm:flex-row bg-[rgba(44, 62, 196, 0.10)] help-bg">
        <div className="flex flex-col gap-8 max-w-[445px]">
          <div className="flex flex-col gap-4">
            <p className="text-[#101820] text-2xl sm:text-4xl font-medium">
              Maçkanalı’nı indirin.
            </p>
            <p className="text-[#101820] text-lg font-normal">
              Sporun ve maçların heyecanını parmaklarınızın ucuna getirin!
            </p>
          </div>

          <div className="flex gap-8 flex-col sm:flex-row">
            <div className="flex flex-col sm:flex-row  gap-8 p-6 bg-white border border-[#E1E1E7] rounded-xl white-bg min-w-72">
              <div className="flex flex-col">
                <p className="text-2xl font-medium">iOS</p>
                <p className="text-lg font-normal text-[#101820] mb-4">
                  Telefon ve tabletiniz için Maçkanalı’nı indirin.
                </p>
                <a href="https://testflight.apple.com/join/5hunmgBr">
                  <img src={ios} width={135} height={45} alt="ios" />
                </a>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row items-center sm:items-start gap-8 p-6 bg-white border border-[#E1E1E7] rounded-xl white-bg min-w-72">
              <div>
                <p className="text-2xl font-medium">Android</p>
                <p className="text-lg font-normal text-[#101820] mt-2 mb-4">
                  Telefon ve tabletiniz için Maçkanalı’nı indirin.
                </p>
                <a href="https://mackanali.com/mackanali.apk">
                  <img src={android} width={135} height={45} alt="ios" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <img src={promotion} alt="promotion" width={524} height="100%" />
      </div>

      <div className="bg-[#F5F5F5] px-[17vw] py-32">
        <p className="text-4xl font-medium">Diğer indirme seçenekleri</p>
        <p className="text-lg font-normal mt-4 mb-14">
          Maç sonuçları ve önemli anlar anında ekranınızda.
        </p>

        <div className="flex gap-8 flex-col sm:flex-row">
          <div className="flex flex-col sm:flex-row items-center sm:items-start gap-8 p-6 bg-white border border-[#E1E1E7] rounded-xl max-w-80">
            <div>
              <p className="text-2xl font-medium">Browser</p>
              <p className="text-lg font-normal text-[#101820] mt-2 mb-4 text-start">
                Maçkanalı’nı web üzerinden kullanmaya başlayın.
              </p>
              <MoreButton text="Maçkanalı Web" />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-center sm:items-start gap-8 p-6 bg-white border border-[#E1E1E7] rounded-xl max-w-80">
            <div>
              <p className="text-2xl font-medium">Android</p>
              <p className="text-lg font-normal text-[#101820] mt-2 mb-4">
                Telefon ve tabletiniz için Maçkanalı’nı indirin.
              </p>
              <a href="https://mackanali.com/mackanali.apk">
                <img src={android} width={135} height={45} alt="ios" />
              </a>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-center sm:items-start gap-8 p-6 bg-white border border-[#E1E1E7] rounded-xl max-w-80">
            <div>
              <p className="text-2xl font-medium">iOS</p>
              <p className="text-lg font-normal text-[#101820] mt-2 mb-4">
                Telefon ve tabletiniz için Maçkanalı’nı indirin.
              </p>
              <a href="https://testflight.apple.com/join/5hunmgBr">
                <img src={ios} width={135} height={45} alt="ios" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <FeatureSlider />
    </div>
  );
};

export default Download;

import { useContext } from "react";
import AdsBanner from "./components/AdsBanner";
import AppPromotion from "./components/AppPromotion";
import Banner from "./components/Banner";
import Comments from "./components/Comments";
import Infos from "./components/Infos";
import Slider from "./components/Slider";

import { DasboardContext } from "./context/context";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  const { menuOpen, show } = useContext(DasboardContext);

  return (
    <div className={`${menuOpen ? "fixed" : "relative"}`}>
      <Header />
      <Banner />
      <Comments />
      <AdsBanner />
      <Infos />
      <Slider />
      <AppPromotion />
      <Footer />
    </div>
  );
}

export default App;

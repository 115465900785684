const MoreButton = ({ text, link }) => {
  return (
    <a
      href={link ? link : "/"}
      className="text-sm text-black flex items-center gap-2 font-semibold w-max group duration-300 hover:text-[#F88939]"
    >
      <p className="relative">
        {text ? text : "Daha Fazla"}
        <div className="bg-[#F88939] w-full h-[3px] z-10"></div>
      </p>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        className="transform transition-transform duration-300 ease group-hover:translate-x-1.5"
      >
        <path
          d="M6.086 3.48573L3.404 0.727179L4.111 0L8 4L4.111 8L3.404 7.27282L6.086 4.51427H0V3.48573H6.086Z"
          fill="black"
        />
      </svg>
    </a>
  );
};

export default MoreButton;

import { gsap } from "gsap";
import { useEffect, useRef } from "react";

import MoreButton from "./MoreButton";

const AdsBanner = () => {
  const phone = useRef(null);
  const text = useRef(null);
  const container = useRef(null);

  useEffect(() => {
    gsap.to(text.current, {
      y: 0,
      opacity: 1,
      duration: 1,
      scrollTrigger: {
        trigger: container.current,
        start: "top center",
        end: "top 100px",
      },
    });

    gsap.to(phone.current, {
      y: 0,
      opacity: 1,
      duration: 1,
      scrollTrigger: {
        trigger: container.current,
        start: "top center",
        end: "top 100px",
      },
    });
  }, []);

  return (
    <div
      className="flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-52 bg-[url('./assets/ads-bg.png')] mt-32 px-4 py-7 sm:px-0 sm:py-0"
      ref={container}
    >
      <div
        className="flex flex-col max-w-md opacity-0 translate-y-40"
        ref={text}
      >
        <p className="khand-medium text-3xl sm:text-6xl text-black">
          <span className="text-[#2C3EC4]">Reklamsız</span> uygulamanın keyfini
          çıkarın
        </p>
        <p className="sm:text-lg text-black font-normal mt-5 mb-8">
          Reklamlardan uzak, maç sonuçlarına anında ve reklamsız erişim ile
          sporun ve maçların keyfini çıkarın.
        </p>
        <MoreButton link="/reklam-yok" />
      </div>
      <div
        className="bg-[url('./assets/ads-banner.png')] w-[300px] sm:w-[400px] h-[425px] sm:h-[650px] bg-center bg-cover opacity-0 translate-y-40"
        ref={phone}
      ></div>
    </div>
  );
};

export default AdsBanner;

import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import slide1 from "../assets/slide-1.png";
import slide2 from "../assets/slide-2.png";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation } from "swiper/modules";

const Slider = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="pr-4 sm:pr-0 pl-4 sm:pl-80 bg-black py-16 sm:py-44 gap-8 flex-col sm:flex sm:flex-row overflow-hidden">
      <div className="sm:min-w-[445px] mb-14 sm:mb-0">
        <p className="text-4xl font-medium text-white mb-8">
          Maçkanalı <br /> güncellemeleri
        </p>
        <p className="text-lg font-normal text-white">
          Uygulamamızda eklenen yeni özellikler ve işlevselliklerle ilgili
          detaylı bilgiler.
        </p>
        <div className="flex mt-8 gap-3">
          <div ref={prevRef}>
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              className="cursor-pointer"
            >
              <circle
                cx="22"
                cy="22"
                r="21.25"
                transform="matrix(-1 0 0 1 46 2)"
                stroke="white"
                stroke-width="1.5"
              />
              <path
                d="M19.828 22.9715L25.192 17.4544L23.778 16L16 24L23.778 32L25.192 30.5456L19.828 25.0285H32V22.9715H19.828Z"
                fill="white"
              />
            </svg>
          </div>
          <div ref={nextRef}>
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              className="cursor-pointer"
            >
              <circle
                cx="24"
                cy="24"
                r="21.25"
                stroke="white"
                stroke-width="1.5"
              />
              <path
                d="M28.172 22.9715L22.808 17.4544L24.222 16L32 24L24.222 32L22.808 30.5456L28.172 25.0285H16V22.9715H28.172Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <Swiper
        spaceBetween={32}
        slidesPerView={1}
        speed={1000}
        loop={true}
        modules={[Navigation]}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
        }}
      >
        <SwiperSlide>
          <div>
            <img src={slide1} alt="slide" className="rounded-t-3xl" />
            <div className="bg-white w-full px-8 py-6  rounded-b-3xl">
              <p className="text-2xl font-medium text-[#101820] mb-3">
                Euro 2024’ü Maçkanalı’ndan takip edin!
              </p>
              <p className="text-lg font-normal text-black">
                Türkiye'nin EURO 2024'te nasıl performans gösterdiğini, son maç
                haberleri, istatistikleri, kadro listesi ve haber
                güncellemelerini öğrenin.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <img src={slide2} alt="slide" className="rounded-t-3xl" />
            <div className="bg-white w-full px-8 py-6  rounded-b-3xl">
              <p className="text-2xl font-medium text-[#101820] mb-3">
                Euro 2024’ü Maçkanalı’ndan takip edin!
              </p>
              <p className="text-lg font-normal text-black">
                Türkiye'nin EURO 2024'te nasıl performans gösterdiğini, son maç
                haberleri, istatistikleri, kadro listesi ve haber
                güncellemelerini öğrenin.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <img src={slide1} alt="slide" className="rounded-t-3xl" />
            <div className="bg-white w-full px-8 py-6  rounded-b-3xl">
              <p className="text-2xl font-medium text-[#101820] mb-3">
                Euro 2024’ü Maçkanalı’ndan takip edin!
              </p>
              <p className="text-lg font-normal text-black">
                Türkiye'nin EURO 2024'te nasıl performans gösterdiğini, son maç
                haberleri, istatistikleri, kadro listesi ve haber
                güncellemelerini öğrenin.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <img src={slide2} alt="slide" className="rounded-t-3xl" />
            <div className="bg-white w-full px-8 py-6  rounded-b-3xl">
              <p className="text-2xl font-medium text-[#101820] mb-3">
                Euro 2024’ü Maçkanalı’ndan takip edin!
              </p>
              <p className="text-lg font-normal text-black">
                Türkiye'nin EURO 2024'te nasıl performans gösterdiğini, son maç
                haberleri, istatistikleri, kadro listesi ve haber
                güncellemelerini öğrenin.
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Slider;

import { useRef } from "react";

import noAds from "../assets/no-ads.png";
import noAds1 from "../assets/noads-1.png";
import noAds2 from "../assets/noads-2.png";
import slider1 from "../assets/slider-1.png";
import slider2 from "../assets/slider-2.png";
import slider3 from "../assets/slider-3.png";
import slider4 from "../assets/slider-4.png";

import MoreButton from "../components/MoreButton";
import Collapsible from "react-collapsible";

import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import FeatureSlider from "../components/FeatureSlider";

const NoAds = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="mt-[100px]">
      <div className="bg-[url('./assets/no-ads-bg.png')] bg-cover flex flex-col sm:flex-row items-center justify-center gap-10 py-10 sm:py-20 px-4 sm:px-0">
        <div className="max-w-[520px]">
          <h3 className="text-3xl sm:text-7xl text-black font-medium mb-5">
            Reklamsız sporun keyfini çıkarın
          </h3>
          <p className="text-lg font-normal text-black">
            Reklamlardan uzak, maç sonuçlarına anında ve reklamsız erişim ile
            sporun ve maçların keyfini çıkarın.
          </p>
        </div>
        <div>
          <img src={noAds} alt="ads" width={524} height={670} />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center gap-8 mt-24 sm:mt-52">
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
          <circle cx="30" cy="30" r="30" fill="#2C3EC4" />
          <path
            d="M13.4639 30.6321C14.9007 22.8058 21.7594 16.875 30.0014 16.875C38.2434 16.875 45.1005 22.8058 46.5389 30.6321C45.102 38.4583 38.2434 44.3891 30.0014 44.3891C21.7594 44.3891 14.9022 38.4583 13.4639 30.6321V30.6321ZM30.0014 38.2749C32.0284 38.2749 33.9723 37.4696 35.4056 36.0363C36.8389 34.603 37.6442 32.659 37.6442 30.6321C37.6442 28.6051 36.8389 26.6611 35.4056 25.2278C33.9723 23.7945 32.0284 22.9892 30.0014 22.9892C27.9744 22.9892 26.0304 23.7945 24.5971 25.2278C23.1638 26.6611 22.3586 28.6051 22.3586 30.6321C22.3586 32.659 23.1638 34.603 24.5971 36.0363C26.0304 37.4696 27.9744 38.2749 30.0014 38.2749ZM30.0014 35.2177C28.7852 35.2177 27.6188 34.7346 26.7588 33.8746C25.8988 33.0146 25.4157 31.8482 25.4157 30.6321C25.4157 29.4159 25.8988 28.2495 26.7588 27.3895C27.6188 26.5295 28.7852 26.0464 30.0014 26.0464C31.2176 26.0464 32.384 26.5295 33.2439 27.3895C34.1039 28.2495 34.587 29.4159 34.587 30.6321C34.587 31.8482 34.1039 33.0146 33.2439 33.8746C32.384 34.7346 31.2176 35.2177 30.0014 35.2177Z"
            fill="white"
          />
        </svg>
        <p className="max-w-[762px] text-3xl sm:text-6xl font-medium text-center khand-medium">
          Reklamlardan uzak, maç sonuçlarına anında ve reklamsız erişim ile
          sporun ve maçların keyfini çıkarın.
        </p>
      </div>

      <div className="flex flex-col gap-20 sm:gap-52 mt-40 px-4 sm:px-0">
        <div className="flex flex-col sm:flex-row justify-center items-center gap-16 sm:gap-32">
          <img src={noAds2} alt="noAds" width={260} height={565} />
          <div className="flex flex-col gap-5 max-w-[560px]">
            <p className="text-lg sm:text-[42px] text-black font-medium">
              Kesintisiz skor güncellemeleri
            </p>
            <p className="text-lg text-black font-normal">
              Maç sonuçlarına anında, reklamsız erişim ile sadece sporun ve
              maçların keyfini çıkarın.
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse sm:flex-row justify-center items-center gap-16 sm:gap-32">
          <div className="flex flex-col gap-5 max-w-[560px]">
            <p className="text-lg sm:text-[42px] text-black font-medium">
              Hızlı ve güvenilir bilgiler
            </p>
            <p className="text-lg text-black font-normal">
              Maç skorlar, canlı güncellemeleri ve oyuncu istatistiklerine
              anında ve doğru şekilde ulaşın, reklamsız olarak takip edin.
            </p>
          </div>
          <img src={noAds1} alt="noAds" width={260} height={565} />
        </div>
      </div>

      <div className="flex flex-col gap-8 px-4 sm:px-[20vw] mt-40">
        <div className="flex flex-col sm:flex-row justify-between gap-6 sm:gap-0">
          <p className="text-3xl sm:text-6xl khand-medium">Yardım merkezi</p>
          <MoreButton text="Tümünü gör" link="/yardim-merkezi" />
        </div>

        <div className="flex flex-col gap-4 pb-32">
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama ücretsiz mi?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama için bir hesap oluşturmalı mıyım?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama ile ilgili bir sorun yaşarsam ne yapmalıyım?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
        </div>
      </div>

      <FeatureSlider />
    </div>
  );
};

export default NoAds;

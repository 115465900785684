const Contact = () => {
  return (
    <div className="mt-[100px]">
      <div className="px-4 sm:px-[17vw] sm:py-32 pb-12">
        <p className="text-4xl sm:text-6xl font-medium khand-medium">
          İletişim
        </p>
        <p className="text-lg font-normal mt-4 mb-8">
          Tüm istek ve önerileriniz için bize ulaşabilirsiniz.
        </p>

        <div className="flex gap-8 flex-col items-center sm:items-baseline sm:flex-row">
         
          <div className="flex flex-col items-center sm:items-start p-8 bg-white border border-[#E1E1E7] rounded-[32px] justify-between min-w-80 h-[350px] max-w-80">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path
                d="M2.66797 9.33325L13.5545 16.9538C14.4361 17.5709 14.8769 17.8795 15.3563 17.999C15.7798 18.1046 16.2228 18.1046 16.6463 17.999C17.1257 17.8795 17.5665 17.5709 18.4481 16.9538L29.3346 9.33325M9.06797 26.6666H22.9346C25.1748 26.6666 26.295 26.6666 27.1506 26.2306C27.9032 25.8471 28.5152 25.2352 28.8987 24.4825C29.3346 23.6269 29.3346 22.5068 29.3346 20.2666V11.7333C29.3346 9.49304 29.3346 8.37294 28.8987 7.51729C28.5152 6.76464 27.9032 6.15272 27.1506 5.76923C26.295 5.33325 25.1748 5.33325 22.9346 5.33325H9.06797C6.82776 5.33325 5.70765 5.33325 4.85201 5.76923C4.09936 6.15272 3.48744 6.76464 3.10394 7.51729C2.66797 8.37294 2.66797 9.49304 2.66797 11.7333V20.2666C2.66797 22.5068 2.66797 23.6269 3.10394 24.4825C3.48744 25.2352 4.09936 25.8471 4.85201 26.2306C5.70765 26.6666 6.82776 26.6666 9.06797 26.6666Z"
                stroke="#2C3EC4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p className="text-3xl font-medium mt-8 mb-4">E-Posta</p>
            <p className="text-lg font-normal text-[#101820] mt-2 mb-10 text-center sm:text-start">
              info@mackanali.com
            </p>

            <a
              href="mailto:info@mackanali.com"
              className="border w-full flex justify-center py-4 rounded-[500px] text-[#2C3EC4] border-[#2C3EC4] text-base font-semibold duration-300 hover:opacity-50"
            >
              E-posta gönderin
            </a>
          </div>
          <div className="flex flex-col items-center sm:items-start p-8 bg-white border border-[#E1E1E7] rounded-[32px] justify-between min-w-80 h-[350px] max-w-80">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path
                d="M15.9987 17.3334C18.2078 17.3334 19.9987 15.5426 19.9987 13.3334C19.9987 11.1243 18.2078 9.33341 15.9987 9.33341C13.7896 9.33341 11.9987 11.1243 11.9987 13.3334C11.9987 15.5426 13.7896 17.3334 15.9987 17.3334Z"
                stroke="#2C3EC4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.9987 29.3334C21.332 24.0001 26.6654 19.2245 26.6654 13.3334C26.6654 7.44238 21.8897 2.66675 15.9987 2.66675C10.1077 2.66675 5.33203 7.44238 5.33203 13.3334C5.33203 19.2245 10.6654 24.0001 15.9987 29.3334Z"
                stroke="#2C3EC4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <p className="text-3xl font-medium mt-8 mb-4">Adres</p>
            <p className="text-lg font-normal text-[#101820] mt-2 mb-10 text-center sm:text-start">
              Esentepe Mah. Talatpaşa Caddesi No:5/1 34394
            </p>

            <a
              href="mailto:info@mackanali.com"
              className="border w-full flex justify-center py-4 rounded-[500px] text-[#2C3EC4] border-[#2C3EC4] text-base font-semibold duration-300 hover:opacity-50"
            >
              Yol tarifi alın
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center mt-32 gap-8">
          <div className="w-full sm:max-w-[40vw] flex flex-col gap-8">
            <p className="text-4xl font-medium text-center">Bize Ulaşın</p>
            <p className="text-lg font-normal text-center">
              Geri bildirim veya öneri sunmak isterseniz, aşağıdaki geri
              bildirim formunu doldurabilirsiniz. Yorumlarınız, uygulamamızı
              geliştirmemize yardımcı olacaktır.
            </p>
            <div className="flex flex-col gap-4 bg-white p-6 rounded-[32px] border shadow-custom">
              <div className="flex flex-col sm:flex-row gap-4 justify-between">
                <input
                  type="text"
                  className="sm:w-[47%] px-4 py-3 bg-white rounded-3xl focus:border-[#2E40C5] duration-300"
                  placeholder="Ad"
                />
                <input
                  type="text"
                  className="sm:w-[47%] px-4 py-3 bg-white rounded-3xl focus:border-[#2E40C5] duration-300"
                  placeholder="Soyad"
                />
              </div>
              <div className="flex flex-col sm:flex-row gap-4 justify-between">
                <input
                  type="text"
                  className="sm:w-[47%] px-4 py-3 bg-white rounded-3xl focus:border-[#2E40C5] duration-300"
                  placeholder="Telefon Numarası"
                />
                <input
                  type="text"
                  className="sm:w-[47%] px-4 py-3 bg-white rounded-3xl focus:border-[#2E40C5] duration-300"
                  placeholder="E-Posta"
                />
              </div>

              <textarea
                type="text"
                className="sm:w-[94%] h-[150px] px-4 py-3 bg-white rounded-3xl focus:border-[#2E40C5] duration-300"
                placeholder="Mesajınız"
              />

              <div className="inline-flex items-center">
                <label
                  className="relative flex items-center p-3 rounded-full cursor-pointer"
                  htmlFor="link"
                >
                  <input
                    type="checkbox"
                    className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity  checked:bg-[#F88939] checked:before:bg-gray-900 hover:before:opacity-10"
                    id="link"
                  />
                  <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg
                      class="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      stroke-width="1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </label>
                <label
                  className="mt-px font-light text-gray-700 cursor-pointer select-none"
                  htmlFor="link"
                >
                  <a
                    href="/"
                    class="flex flex-col sm:flex-row flex-nowrap font-sans text-xs sm:text-base antialiased font-medium leading-relaxed text-blue-gray-900"
                  >
                    <p className="underline text-nowrap">
                      Kişisel Verilerin Korunması Kanunu
                      <br className="sm:hidden" />
                    </p>
                    <p className="text-nowrap font-sans text-xs sm:text-base antialiased font-medium leading-relaxed text-blue-500 transition-colors hover:text-blue-700">
                      ’nu okudum, anladım.
                    </p>
                  </a>
                </label>
              </div>
              <a
                href="/"
                className="w-full flex bg-[#2C3EC4] py-4 rounded-3xl justify-center items-center text-white text-base duration-300 hover:bg-black"
              >
                Gönder
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

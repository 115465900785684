import { useEffect, useRef } from "react";
import gsap from "gsap";

import qr from "../assets/qr.png";
import promotion from "../assets/promotion.png";

const AppPromotion = () => {
  const left = useRef(null);
  const right = useRef(null);
  const container = useRef(null);

  useEffect(() => {
    gsap.to(left.current, {
      y: 0,
      opacity: 1,
      duration: 1,
      delay: 1,
      scrollTrigger: {
        trigger: container.current,
        start: "top center",
        end: "top 100px",
      },
    });
    gsap.to(right.current, {
      y: 0,
      opacity: 1,
      duration: 1,
      scrollTrigger: {
        trigger: container.current,
        start: "top center",
        end: "top 100px",
      },
    });
  }, []);

  return (
    <div
      className="sm:pt-44 sm:pl-60 flex flex-col sm:flex-row gap-8 bg-[rgba(44, 62, 196, 0.10)] px-4 sm:p-0 mt-16 sm:mt-0"
      ref={container}
    >
      <div
        className="flex flex-col gap-8 max-w-[445px] sm:opacity-0  sm:translate-y-40"
        ref={left}
      >
        <div className="flex flex-col gap-4">
          <p className="text-[#101820] text-2xl sm:text-4xl font-medium">
            Maçkanalı mobil uygulamasını indirin.
          </p>
          <p className="text-[#101820] text-lg font-normal">
            Sporun ve maçların heyecanını parmaklarınızın ucuna getirin!
          </p>
        </div>
        <div className="flex gap-4">
          <a
            href="https://mackanali.com/mackanali.apk"
            className="flex items-center justify-center gap-1 w-[130px] h-[50px] rounded-[500px] text-[#fff] bg-[#2C3EC4] duration-300 hover:bg-black"
          >
            <p className="font-semibold">İndir</p>

            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 11.6316H12V13H0V11.6316ZM6.66667 7.644L10.714 3.48947L11.6567 4.45695L6 10.2632L0.343333 4.45763L1.286 3.48947L5.33333 7.64263V0H6.66667V7.644Z"
                fill="white"
              />
            </svg>
          </a>
          <a
            href="http://mobile.mackanali.com/login"
            className="flex items-center justify-center gap-1 w-[130px] h-[50px] rounded-[500px] text-[#2C3EC4] bg-[#fff] border border-[#2C3EC4] duration-300 hover:opacity-50"
          >
            <p className="font-semibold">MaçWeb</p>
          </a>
        </div>
        <div className="flex flex-col sm:flex-row items-center sm:items-start gap-8 p-8 bg-[rgba(255, 255, 255, 0.50)] border border-[#E1E1E7] rounded-xl">
          <img src={qr} width={108} height={108} alt="QR code" />
          <div>
            <p className="text-lg font-normal text-[#101820] mb-4 text-center sm:text-start">
              QR kodu okutarak cihazınıza Maçkanalı uygulamasını indirin!
            </p>
            <div className="flex items-center gap-8 justify-center sm:justify-start">
              <svg width="32" height="37" viewBox="0 0 32 37" fill="none">
                <path
                  d="M0.61745 1.35145C0.17489 1.89225 -0.0429218 2.57854 0.00702229 3.27307V33.6346C-0.0401472 34.3346 0.194313 35.0264 0.65907 35.5562L0.756184 35.666L17.9037 18.6597V18.2479L0.714564 1.25537L0.61745 1.35145Z"
                  fill="url(#paint0_linear_102_5072)"
                />
                <path
                  d="M23.5943 24.3279L17.9062 18.6592V18.2474L23.5943 12.5786L23.7192 12.661L30.531 16.4767C32.4732 17.5611 32.4732 19.3454 30.531 20.4435L23.7469 24.2593L23.5943 24.3279Z"
                  fill="url(#paint1_linear_102_5072)"
                />
                <path
                  d="M23.7583 24.2643L17.9038 18.4583L0.617676 35.5608C1.42649 36.2732 2.63347 36.313 3.48945 35.6569L23.7583 24.2643Z"
                  fill="url(#paint2_linear_102_5072)"
                />
                <path
                  d="M23.7583 12.6624L3.48945 1.27001C2.63902 0.604306 1.42788 0.63862 0.617676 1.35236L17.9038 18.4547L23.7583 12.6624Z"
                  fill="url(#paint3_linear_102_5072)"
                />
                <path
                  opacity="0.2"
                  d="M23.5919 24.1265L3.48945 35.4502C2.66815 36.0637 1.53609 36.0637 0.714789 35.4502L0.617676 35.5462L0.714789 35.656C1.53609 36.2696 2.66815 36.2696 3.48945 35.656L23.7583 24.2637L23.5919 24.1265Z"
                  fill="black"
                />
                <path
                  opacity="0.12"
                  d="M0.618507 35.3686C0.173173 34.8237 -0.0460262 34.1319 0.00807985 33.4332V33.6391C-0.0390895 34.3392 0.19537 35.0309 0.660127 35.5608L0.75724 35.4647L0.618507 35.3686ZM30.5294 20.2426L23.5928 24.1271L23.7176 24.2643L30.5294 20.4348C31.348 20.0916 31.9126 19.334 32 18.4583C31.8238 19.2502 31.2786 19.9132 30.5294 20.2426Z"
                  fill="black"
                />
                <path
                  opacity="0.25"
                  d="M3.48879 1.46544L30.5279 16.6737C31.277 17.0031 31.8222 17.666 31.9984 18.458C31.911 17.5823 31.3464 16.8246 30.5279 16.4815L3.48879 1.27328C1.5604 0.175217 0.0065918 1.08112 0.0065918 3.27725V3.48314C0.0065918 1.28701 1.5604 0.381104 3.48879 1.46544Z"
                  fill="white"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_102_5072"
                    x1="10.9194"
                    y1="2.95732"
                    x2="-3.64716"
                    y2="17.6807"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#00A0FF" />
                    <stop offset="0.01" stop-color="#00A1FF" />
                    <stop offset="0.26" stop-color="#00BEFF" />
                    <stop offset="0.51" stop-color="#00D2FF" />
                    <stop offset="0.76" stop-color="#00DFFF" />
                    <stop offset="1" stop-color="#00E3FF" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_102_5072"
                    x1="33.0698"
                    y1="18.4533"
                    x2="-0.489689"
                    y2="18.4533"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FFE000" />
                    <stop offset="0.41" stop-color="#FFBD00" />
                    <stop offset="0.78" stop-color="#FFA500" />
                    <stop offset="1" stop-color="#FF9C00" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_102_5072"
                    x1="20.5814"
                    y1="23.912"
                    x2="-4.33921"
                    y2="49.0892"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FF3A44" />
                    <stop offset="1" stop-color="#C31162" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_102_5072"
                    x1="-3.73856"
                    y1="-1.31055"
                    x2="7.38302"
                    y2="9.93056"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#32A071" />
                    <stop offset="0.07" stop-color="#2DA771" />
                    <stop offset="0.48" stop-color="#15CF74" />
                    <stop offset="0.8" stop-color="#06E775" />
                    <stop offset="1" stop-color="#00F076" />
                  </linearGradient>
                </defs>
              </svg>
              <svg width="32" height="39" viewBox="0 0 32 39" fill="none">
                <path
                  d="M23.7755 9.4191C26.6588 9.50846 29.3303 10.9365 30.9839 13.2754C28.3732 14.8633 26.7665 17.6627 26.7283 20.6942C26.7318 24.1243 28.8075 27.221 32 28.5563C31.3869 30.5309 30.461 32.3954 29.2573 34.083C27.642 36.4734 25.9484 38.8089 23.2596 38.8536C20.6472 38.912 19.77 37.3327 16.7738 37.3327C13.7514 37.3327 12.8135 38.8106 10.3088 38.9137C7.7485 39.0065 5.79267 36.36 4.11824 33.9919C0.771108 29.1561 -1.83434 20.3608 1.6587 14.377C3.29839 11.4607 6.36587 9.60985 9.73732 9.50159C12.3011 9.45003 14.6807 11.2149 16.2561 11.2149C17.802 11.2149 20.7427 9.10461 23.7755 9.4191ZM23.8067 0C23.9804 2.25983 23.2596 4.49731 21.7936 6.23816C20.371 7.98759 18.2154 8.99635 15.947 8.97229C15.8028 6.77776 16.5462 4.61761 18.014 2.96613C19.4991 1.29231 21.5678 0.233716 23.8067 0Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <img
        src={promotion}
        alt="promotion"
        ref={right}
        className="sm:opacity-0  sm:translate-y-40"
      />
    </div>
  );
};

export default AppPromotion;

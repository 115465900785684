import { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Comments = () => {
  const topComment = useRef(null);
  const text = useRef(null);
  const bottomComment = useRef(null);
  const container = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: container.current,
        start: "top center",
        end: "top 100px",
      },
    });

    tl.to(topComment.current, {
      opacity: 1,
      y: 0,
      duration: 1,
    })
      .to(text.current, {
        opacity: 1,
        y: 0,
        duration: 1,
      })
      .to(bottomComment.current, {
        opacity: 1,
        y: 0,
        duration: 1,
      });
  }, []);

  return (
    <div
      className="flex flex-col items-center justify-center gap-10 mt-14"
      ref={container}
    >
      <div
        className="bg-[url('./assets/comment-1-mobile.png')] sm:bg-[url('./assets/comments.png')] w-[345px] sm:w-[1080px] h-[150px] sm:h-[270px] bg-cover opacity-0  translate-y-40"
        ref={topComment}
      ></div>
      <p
        className="sm:text-4xl p-4 sm:p-0 font-medium text-black max-w-[760px] text-center opacity-0  translate-y-40"
        ref={text}
      >
        İster tutkulu bir spor hayranı, ister ciddi bir futbol fanatiği olun.
        Canlı skorlardan detaylı istatistiklere ve oyuncu analizlerine kadar her
        şey tek bir platformda!
      </p>
      <div
        className="bg-[url('./assets/comment-2-mobile.png')] sm:bg-[url('./assets/comments.png')] w-[345px] sm:w-[1080px] h-[150px] sm:h-[270px] bg-cover opacity-0  translate-y-40"
        ref={bottomComment}
      ></div>
    </div>
  );
};

export default Comments;

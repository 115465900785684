import { useState } from "react";

import blog1 from "../assets/blog-1.png";
import MoreButton from "../components/MoreButton";

const Blog = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="relative">
      <div className="mt-[100px] sm:mt-[160px] px-4 sm:px-[17vw]">
        <h3 className="hkand-medium text-4xl sm:text-6xl font-normal mb-8 sm:mb-14">
          Maçkanalı Güncellemeleri
        </h3>
        <div>
          <div className="shadow-blog p-6 rounded-[32px] flex flex-col sm:flex-row gap-3  sm:gap-8 items-center mb-8">
            <img src={blog1} alt="blog" width={500} height={375} />
            <div className="flex flex-col  gap-2 sm:gap-5">
              <p className="text-xs text-[#898989]">12 Eylül 2022</p>
              <p className="text-3xl font-normal">
                Euro 2024’ü Maçkanalı’ndan takip edin!
              </p>
              <p className="text-lg font-normal mb-4">
                Türkiye'nin EURO 2024'te nasıl performans gösterdiğini, son maç
                haberleri, istatistikleri, kadro listesi ve haber
                güncellemelerini öğrenin.
              </p>
              <MoreButton text="İncele" link="/blog-detay" />
            </div>
          </div>

          <div className="flex justify-between flex-wrap gap-y-6">
            <div className="shadow-blog rounded-[32px] flex flex-col items-center w-full sm:w-[48%]">
              <div className="bg-[url('./assets/blog-2.png')] h-[295px] bg-cover rounded-t-[32px] w-full"></div>
              <div className="py-6 px-8 flex flex-col gap-3">
                <p className="text-2xl text-[#101820] font-medium">
                  EURO 2024 Final | İspanya - İngiltere
                </p>
                <p className="text-black text-lg font-normal">
                  Avrupa futbolunun en büyüğü İspanya... Matadorlar, Euro 2024
                  finalinde İngiltere'yi 2-1 ile geçip, tarihinde 4'üncü kez
                  kupaya uzandı. De La Fuente'nin ekibi, böylece en...
                </p>
                <MoreButton text="İncele" link="/blog-detay" />
              </div>
            </div>

            <div className="shadow-blog rounded-[32px] flex flex-col items-center w-full sm:w-[48%]">
              <div className="bg-[url('./assets/blog-3.png')] h-[295px] bg-cover rounded-t-[32px] w-full"></div>
              <div className="py-6 px-8 flex flex-col gap-3">
                <p className="text-2xl text-[#101820] font-medium">
                  Trendyol Süper Lig'de 2024-2025 sezonu güncellemesi geldi!
                </p>
                <p className="text-black text-lg font-normal">
                  Senectus rhoncus etiam viverra ligula elit erat euismod ornare
                  vehicula donec.
                </p>
                <MoreButton text="İncele" link="/blog-detay" />
              </div>
            </div>

            <div className="shadow-blog rounded-[32px] flex flex-col items-center w-full sm:w-[48%]">
              <div className="bg-[url('./assets/blog-4.png')] h-[295px] bg-cover rounded-t-[32px] w-full"></div>
              <div className="py-6 px-8 flex flex-col gap-3">
                <p className="text-2xl text-[#101820] font-medium">
                  Trendyol Süper Lig'de 2024-2025 sezonu güncellemesi geldi!
                </p>
                <p className="text-black text-lg font-normal">
                  Senectus rhoncus etiam viverra ligula elit erat euismod ornare
                  vehicula donec.
                </p>
                <MoreButton text="İncele" link="/blog-detay" />
              </div>
            </div>

            <div className="shadow-blog rounded-[32px] flex flex-col items-center w-full sm:w-[48%]">
              <div className="bg-[url('./assets/blog-5.png')] h-[295px] bg-cover rounded-t-[32px] w-full"></div>
              <div className="py-6 px-8 flex flex-col gap-3">
                <p className="text-2xl text-[#101820] font-medium">
                  Trendyol Süper Lig'de 2024-2025 sezonu güncellemesi geldi!
                </p>
                <p className="text-black text-lg font-normal">
                  Senectus rhoncus etiam viverra ligula elit erat euismod ornare
                  vehicula donec.
                </p>
                <MoreButton text="İncele" link="/blog-detay" />
              </div>
            </div>

            <div className="shadow-blog rounded-[32px] flex flex-col items-center w-full sm:w-[48%]">
              <div className="bg-[url('./assets/blog-6.png')] h-[295px] bg-cover rounded-t-[32px] w-full"></div>
              <div className="py-6 px-8 flex flex-col gap-3">
                <p className="text-2xl text-[#101820] font-medium">
                  Trendyol Süper Lig'de 2024-2025 sezonu güncellemesi geldi!
                </p>
                <p className="text-black text-lg font-normal">
                  Senectus rhoncus etiam viverra ligula elit erat euismod ornare
                  vehicula donec.
                </p>
                <MoreButton text="İncele" link="/blog-detay" />
              </div>
            </div>

            <div className="shadow-blog rounded-[32px] flex flex-col items-center w-full sm:w-[48%]">
              <div className="bg-[url('./assets/blog-7.png')] h-[295px] bg-cover rounded-t-[32px] w-full"></div>
              <div className="py-6 px-8 flex flex-col gap-3">
                <p className="text-2xl text-[#101820] font-medium">
                  Trendyol Süper Lig'de 2024-2025 sezonu güncellemesi geldi!
                </p>
                <p className="text-black text-lg font-normal">
                  Senectus rhoncus etiam viverra ligula elit erat euismod ornare
                  vehicula donec.
                </p>
                <MoreButton text="İncele" link="/blog-detay" />
              </div>
            </div>

            <div className="shadow-blog rounded-[32px] flex flex-col items-center w-full sm:w-[48%]">
              <div className="bg-[url('./assets/blog-8.png')] h-[295px] bg-cover rounded-t-[32px] w-full"></div>
              <div className="py-6 px-8 flex flex-col gap-3">
                <p className="text-2xl text-[#101820] font-medium">
                  Trendyol Süper Lig'de 2024-2025 sezonu güncellemesi geldi!
                </p>
                <p className="text-black text-lg font-normal">
                  Senectus rhoncus etiam viverra ligula elit erat euismod ornare
                  vehicula donec.
                </p>
                <MoreButton text="İncele" link="/blog-detay" />
              </div>
            </div>

            <div className="shadow-blog rounded-[32px] flex flex-col items-center w-full sm:w-[48%]">
              <div className="bg-[url('./assets/blog-9.png')] h-[295px] bg-cover rounded-t-[32px] w-full"></div>
              <div className="py-6 px-8 flex flex-col gap-3">
                <p className="text-2xl text-[#101820] font-medium">
                  Trendyol Süper Lig'de 2024-2025 sezonu güncellemesi geldi!
                </p>
                <p className="text-black text-lg font-normal">
                  Senectus rhoncus etiam viverra ligula elit erat euismod ornare
                  vehicula donec.
                </p>
                <MoreButton text="İncele" link="/blog-detay" />
              </div>
            </div>
          </div>

          <div className="flex w-full justify-center">
            <a
              href="/"
              className="flex items-center justify-center gap-1 w-[175px] h-[50px] rounded-[500px] text-[#fff] bg-[#2C3EC4]  border-[#2C3EC4] duration-300 hover:bg-black mt-32 mb-28"
            >
              <p>Daha fazla göster</p>
            </a>
          </div>
        </div>
      </div>
      <div className="py-16 flex flex-col justify-center items-center gap-8 mobile-bg">
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
          <circle cx="30" cy="30" r="30" fill="#2C3EC4" />
          <path
            d="M26.875 15.9375H33.125C36.4402 15.9375 39.6196 17.2545 41.9638 19.5987C44.308 21.9429 45.625 25.1223 45.625 28.4375C45.625 31.7527 44.308 34.9321 41.9638 37.2763C39.6196 39.6205 36.4402 40.9375 33.125 40.9375V46.4062C25.3125 43.2812 14.375 38.5938 14.375 28.4375C14.375 25.1223 15.692 21.9429 18.0362 19.5987C20.3804 17.2545 23.5598 15.9375 26.875 15.9375V15.9375Z"
            fill="white"
          />
        </svg>
        <p className="text-4xl">Talepte Bulunun</p>
        <p className="text-lg text-[#898989]">
          Taleplerini bize iletebilirsiniz.
        </p>
        <div
          onClick={() => setMenuOpen(true)}
          class="cursor-pointer flex items-center justify-center gap-1 w-[130px] h-[50px] rounded-[500px] text-[#2C3EC4] bg-transparent border border-[#2C3EC4] duration-300 hover:opacity-50"
        >
          <p>Talep Formu</p>
        </div>
      </div>

      {menuOpen && (
        <div className="flex fixed top-0 left-0 right-0 bottom-0 z-[9999999999999999999]">
          <div
            onClick={() => setMenuOpen(false)}
            className="bg-black sm:w-1/2 opacity-50 h-[100vh]"
          ></div>
          <div className="bg-white py-12 w-full sm:w-1/2 h-[100vh] px-[6vw] flex flex-col sm:justify-center overflow-scroll sm:py-24">
            <div
              className="absolute top-1 right-4 sm:top-7 sm:right-7 cursor-pointer scale-75 sm:scale-100"
              onClick={() => setMenuOpen(false)}
            >
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
                <circle
                  cx="30"
                  cy="30"
                  r="26.75"
                  stroke="black"
                  stroke-width="1.5"
                />
                <path
                  d="M30 27.7781L37.7781 20L40 22.2219L32.2219 30L40 37.7781L37.7781 40L30 32.2219L22.2219 40L20 37.7781L27.7781 30L20 22.2219L22.2219 20L30 27.7781Z"
                  fill="#09121F"
                />
              </svg>
            </div>
            <p className="text-4xl mb-8 font-normal">Talepte Bulunun</p>
            <div className="flex flex-col gap-4 bg-white p-6 rounded-[32px] border shadow-custom">
              <div className="flex flex-col sm:flex-row gap-4 justify-between">
                <input
                  type="text"
                  className="sm:w-[47%] px-4 py-3 bg-white rounded-3xl focus:border-[#2E40C5] duration-300"
                  placeholder="Ad"
                />
                <input
                  type="text"
                  className="sm:w-[47%] px-4 py-3 bg-white rounded-3xl focus:border-[#2E40C5] duration-300"
                  placeholder="Soyad"
                />
              </div>
              <div className="flex flex-col sm:flex-row gap-4 justify-between">
                <input
                  type="text"
                  className="sm:w-[47%] px-4 py-3 bg-white rounded-3xl focus:border-[#2E40C5] duration-300"
                  placeholder="Telefon Numarası"
                />
                <input
                  type="text"
                  className="sm:w-[47%] px-4 py-3 bg-white rounded-3xl focus:border-[#2E40C5] duration-300"
                  placeholder="E-Posta"
                />
              </div>

              <textarea
                type="text"
                className="sm:w-[94%] h-[150px] px-4 py-3 bg-white rounded-3xl focus:border-[#2E40C5] duration-300"
                placeholder="Mesajınız"
              />

              <div className="inline-flex items-center">
                <label
                  className="relative flex items-center p-3 rounded-full cursor-pointer"
                  htmlFor="link"
                >
                  <input
                    type="checkbox"
                    className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity  checked:bg-[#F88939] checked:before:bg-gray-900 hover:before:opacity-10"
                    id="link"
                  />
                  <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <svg
                      class="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      stroke-width="1"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                </label>
                <label
                  className="mt-px font-light text-gray-700 cursor-pointer select-none"
                  htmlFor="link"
                >
                  <a
                    href="/"
                    class="flex flex-col sm:flex-row flex-nowrap font-sans text-xs sm:text-base antialiased font-medium leading-relaxed text-blue-gray-900"
                  >
                    <p className="underline text-nowrap">
                      Kişisel Verilerin Korunması Kanunu
                      <br className="sm:hidden" />
                    </p>
                    <p className="text-nowrap font-sans text-xs sm:text-base antialiased font-medium leading-relaxed text-blue-500 transition-colors hover:text-blue-700">
                      ’nu okudum, anladım.
                    </p>
                  </a>
                </label>
              </div>
              <a
                href="/"
                className="w-full flex bg-[#2C3EC4] py-4 rounded-3xl justify-center items-center text-white text-base duration-300 hover:bg-black"
              >
                Gönder
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blog;

import { useRef } from "react";

import fastBanner from "../assets/fast-banner.png";
import drops from "../assets/drops.png";
import dropsUnder from "../assets/drops-under.png";
import info1 from "../assets/info-1.png";
import group from "../assets/group.png";
import fastDetail from "../assets/fast-detail.png";

import slider1 from "../assets/slider-1.png";
import slider2 from "../assets/slider-2.png";
import slider3 from "../assets/slider-3.png";
import slider4 from "../assets/slider-4.png";

import "swiper/css/navigation";
import "swiper/css/pagination";

import MoreButton from "../components/MoreButton";
import Collapsible from "react-collapsible";

import { Navigation } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import FeatureSlider from "../components/FeatureSlider";

const FastNotification = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="mt-[100px]">
      <div className="bg-[url('./assets/fast-not.png')] bg-cover flex flex-col sm:flex-row items-center justify-center gap-10 py-10 sm:py-20 px-4 sm:px-0">
        <div className="max-w-[520px]">
          <h3 className="text-4xl sm:text-7xl text-black font-medium mb-5">
            Tüm skorlar ve sonuçlar anında ekranınızda
          </h3>
          <p className="text-lg font-normal text-black">
            Futbol, basketbol, voleybol, tenis ve buz hokeyine kadar, 5000'den
            fazla lig ve turnuva sonuçlarını ilk siz öğrenin. Maçkanalı
            uygulamasıyla hiçbir şeyi kaçırmayın! ⚽🏀🎾🏐
          </p>
        </div>
        <div>
          <img src={fastBanner} alt="fast" width={524} height={670} />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center gap-8 mt-24 sm:mt-52 mb-24 sm:mb-60">
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
          <circle cx="30" cy="30" r="30" fill="#2C3EC4" />
          <path
            d="M42.5 37.8125H45.625V40.9375H14.375V37.8125H17.5V26.875C17.5 23.5598 18.817 20.3804 21.1612 18.0362C23.5054 15.692 26.6848 14.375 30 14.375C33.3152 14.375 36.4946 15.692 38.8388 18.0362C41.183 20.3804 42.5 23.5598 42.5 26.875V37.8125ZM25.3125 44.0625H34.6875V47.1875H25.3125V44.0625Z"
            fill="white"
          />
        </svg>

        <p className="max-w-[762px] text-3xl sm:text-6xl font-medium text-center khand-medium">
          Maçkanalı uygulamasıyla hiçbir şeyi kaçırmayın!
        </p>
      </div>

      <div className="flex flex-col sm:flex-row items-center justify-center gap-16 mb-24 sm:mb-60 bg-[#F5F5F5] py-12 sm:py-32 px-4 sm:px-0">
        <div className="flex flex-col">
          <p className="text-4xl text-black font-medium mb-12">
            Anında maç skorları: <br /> hiçbir anı kaçırmayın!
          </p>
          <img
            src={drops}
            alt="drops"
            width={410}
            height={65}
            className="mb-6"
          />
          <img src={dropsUnder} alt="drops" width={410} height={255} />
        </div>
        <div className="flex flex-col gap-8">
          <div>
            <p className="text-lg font-normal">
              Uygulamamız, tüm maç skorlarını ve sonuçlarını anında size
              sunarak, hiçbir <br /> önemli detayı kaçırmamanızı sağlar.
            </p>
          </div>
          <div className="flex flex-col sm:flex-row items-center sm:items-baseline">
            <img src={info1} alt="info" width={200} height={375} />
            <img src={group} alt="group" width={400} height={370} />
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row items-center justify-center gap-16 sm:gap-32 px-4 sm:px-0">
        <img src={fastDetail} alt="detail" width={260} height={565} />
        <div className="max-w-[500px] flex flex-col">
          <div className="py-6 border-y border-[#E1E1E7] relative">
            <div className="w-[200px] h-[1px] bg-[#F88939] absolute -top-0"></div>
            <p className="text-2xl font-medium mb-5">
              Kullanıcı dostu arayüz ve detaylı istatistikler
            </p>
            <p className="text-lg font-normal">
              Senectus rhoncus etiam viverra ligula elit erat euismod ornare
              nisl. Pulvinar non mauris curabitur; nunc leo dolor vehicula
              donec.
            </p>
          </div>
          <p className="text-2xl font-medium py-6 border-b border-[#E1E1E7]">
            Gerçek zamanlı skorlar
          </p>
          <p className="text-2xl font-medium border-b border-[#E1E1E7] py-6">
            Detaylı istatistikler
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-8 px-4 sm:px-[20vw] mt-40">
        <div className="flex flex-col sm:flex-row justify-between gap-6 sm:gap-0">
          <p className="text-3xl sm:text-6xl khand-medium">Yardım merkezi</p>
          <MoreButton text="Tümünü gör" link="/yardim-merkezi" />
        </div>

        <div className="flex flex-col gap-4 pb-32">
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama ücretsiz mi?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama için bir hesap oluşturmalı mıyım?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama ile ilgili bir sorun yaşarsam ne yapmalıyım?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
        </div>
      </div>

      <FeatureSlider />
    </div>
  );
};

export default FastNotification;

import { useRef } from "react";

import slider1 from "../assets/slider-1.png";
import slider2 from "../assets/slider-2.png";
import slider3 from "../assets/slider-3.png";
import slider4 from "../assets/slider-4.png";

import { Navigation } from "swiper/modules";

import "swiper/css/navigation";
import "swiper/css/pagination";

import { Swiper, SwiperSlide } from "swiper/react";

import MoreButton from "../components/MoreButton";

const FeatureSlider = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="flex flex-col sm:flex-row px-4 sm:pl-[20vw] gap-8 mobile-bg py-16 sm:py-32">
      <div className="sm:min-w-[445px]">
        <p className="text-4xl mb-4">Maçkanalı’nı keşfet</p>
        <p className="text-lg font-normal">
          Gerçek zamanlı maç skorları ve oyuncu istatistikleri.
        </p>
        <div className="flex mt-8 gap-3">
          <div ref={prevRef} className="cursor-pointer">
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none">
              <circle
                cx="22"
                cy="22"
                r="21.25"
                transform="matrix(-1 0 0 1 44 0)"
                stroke="black"
                stroke-width="1.5"
              />
              <path
                d="M17.828 20.9715L23.192 15.4544L21.778 14L14 22L21.778 30L23.192 28.5456L17.828 23.0285H30V20.9715H17.828Z"
                fill="black"
              />
            </svg>
          </div>
          <div ref={nextRef} className="cursor-pointer">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="24"
                cy="24"
                r="21.25"
                stroke="black"
                stroke-width="1.5"
              />
              <path
                d="M28.172 22.9715L22.808 17.4544L24.222 16L32 24L24.222 32L22.808 30.5456L28.172 25.0285H16V22.9715H28.172Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>
      <Swiper
        className="w-full"
        slidesPerView={1}
        spaceBetween={32}
        speed={1000}
        loop={true}
        modules={[Navigation]}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
        }}
      >
        <SwiperSlide>
          <div>
            <img src={slider4} alt="ads" />
            <div>
              <p className="text-3xl text-[#101820] my-8">
                Reklamsız uygulamanın keyfini çıkarın
              </p>
              <MoreButton text="İncele" link="/reklam-yok" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <img src={slider3} alt="ads" />
            <div>
              <p className="text-3xl text-[#101820] my-8">
                Tüm skorlar ve sonuçlar anında ekranınızda
              </p>
              <MoreButton text="İncele" link="/hizli-bildirimler" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <img src={slider2} alt="ads" />
            <div>
              <p className="text-3xl text-[#101820] my-8">
                Maç heyecanını herkes ile paylaşın
              </p>
              <MoreButton text="İncele" link="/yorum-yapin" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div>
            <img src={slider1} alt="ads" />
            <div>
              <p className="text-3xl text-[#101820] my-8">
                Tüm lig sonuçlarından haberdar olun
              </p>
              <MoreButton text="İncele" link="/tum-ligler" />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default FeatureSlider;

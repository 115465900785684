import { useRef } from "react";

import commentBanner from "../assets/comment-banner.png";
import commentDetail from "../assets/comment-detail.png";

import MoreButton from "../components/MoreButton";
import Collapsible from "react-collapsible";

import "swiper/css/navigation";
import "swiper/css/pagination";

import FeatureSlider from "../components/FeatureSlider";

const Comment = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="mt-[100px]">
      <div className="bg-[url('./assets/comment-mobil-bg.png')] bg-right sm:bg-[url('./assets/comment-bg.png')] bg-left sm:bg-center sm:bg-cover flex flex-col sm:flex-row items-center justify-center gap-10 py-10 sm:py-20 px-4 sm:px-0">
        <div className="max-w-[520px]">
          <h3 className="text-4xl sm:text-7xl text-black font-medium mb-5">
            Maç heyecanını herkes ile paylaşın
          </h3>
          <p className="text-lg font-normal text-black">
            Detaylı istatistikler ve analizler ile sohbetlerinizi zenginleştirin
            ve sporun coşkusunu birlikte yaşayın.
          </p>
        </div>
        <div>
          <img src={commentBanner} alt="comment" width={524} height={670} />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center gap-8 mt-24 sm:mt-52 mb-4 sm:mb-60">
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
          <circle cx="30" cy="30" r="30" fill="#2C3EC4" />
          <path
            d="M26.875 15.9375H33.125C36.4402 15.9375 39.6196 17.2545 41.9638 19.5987C44.308 21.9429 45.625 25.1223 45.625 28.4375C45.625 31.7527 44.308 34.9321 41.9638 37.2763C39.6196 39.6205 36.4402 40.9375 33.125 40.9375V46.4062C25.3125 43.2812 14.375 38.5938 14.375 28.4375C14.375 25.1223 15.692 21.9429 18.0362 19.5987C20.3804 17.2545 23.5598 15.9375 26.875 15.9375V15.9375Z"
            fill="white"
          />
        </svg>

        <p className="max-w-[762px] text-3xl px-4 sm:px-0 sm:text-6xl font-medium text-center khand-medium">
          Sporun heyecanını parmaklarınızın ucunda yaşayın!
        </p>
      </div>

      <div className="flex flex-col gap-52 mt-40 px-4 sm:px-0">
        <div className="flex flex-col sm:flex-row justify-center items-center gap-14 sm:gap-32">
          <img src={commentDetail} alt="noAds" width={260} height={565} />
          <div className="flex flex-col gap-5 max-w-[560px]">
            <p className="text-2xl sm:text-[42px] text-black font-medium">
              Sporun keyfini birlikte çıkarın
            </p>
            <p className="text-lg text-black font-normal">
              Maç özetlerine ve önemli anlara dair kişisel yorumlarınızı
              paylaşarak diğer kullanıcılarla etkileşime geçin.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-8 px-4 sm:px-[20vw] mt-40">
        <div className="flex flex-col sm:flex-row justify-between gap-6 sm:gap-0">
          <p className="text-3xl sm:text-6xl khand-medium">Yardım merkezi</p>
          <MoreButton text="Tümünü gör" link="/yardim-merkezi" />
        </div>

        <div className="flex flex-col gap-4 pb-32">
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama ücretsiz mi?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama için bir hesap oluşturmalı mıyım?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama ile ilgili bir sorun yaşarsam ne yapmalıyım?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
        </div>
      </div>

      <FeatureSlider />
    </div>
  );
};

export default Comment;

import React from "react";
import ReactDOM from "react-dom/client";
import "./output.css";
import "./font.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from "./App";
import NoAds from "./pages/NoAds";
import Header from "./components/Header";
import Footer from "./components/Footer";
import FastNotification from "./pages/FastNotification";
import Comment from "./pages/Comment";
import AllLeagues from "./pages/AllLeagues";
import Privacy from "./pages/Privacy";
import Help from "./pages/Help";
import { DashboardProvider } from "./context/context";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import Download from "./pages/Download";
import Contact from "./pages/Contact";
import HeaderLayout from "./components/HeaderLayout";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route
          path="/reklam-yok"
          element={
            <>
              <Header />
              <NoAds />
              <Footer />
            </>
          }
        />
        <Route
          path="/hizli-bildirimler"
          element={
            <HeaderLayout>
              <Header />
              <FastNotification />
              <Footer />
            </HeaderLayout>
          }
        />
        <Route
          path="/yorum-yapin"
          element={
            <HeaderLayout>
              <Header />
              <Comment />
              <Footer />
            </HeaderLayout>
          }
        />
        <Route
          path="/tum-ligler"
          element={
            <HeaderLayout>
              <Header />
              <AllLeagues />
              <Footer />
            </HeaderLayout>
          }
        />
        <Route
          path="/gizlilik"
          element={
            <HeaderLayout>
              <Header />
              <Privacy />
              <Footer />
            </HeaderLayout>
          }
        />
        <Route
          path="/yardim-merkezi"
          element={
            <HeaderLayout>
              <Header />
              <Help />
              <Footer />
            </HeaderLayout>
          }
        />
        <Route
          path="/blog"
          element={
            <HeaderLayout>
              <Header />
              <Blog />
              <Footer />
            </HeaderLayout>
          }
        />
        <Route
          path="/blog-detay"
          element={
            <HeaderLayout>
              <Header />
              <BlogDetail />
              <Footer />
            </HeaderLayout>
          }
        />
        <Route
          path="/indir"
          element={
            <HeaderLayout>
              <Header />
              <Download />
              <Footer />
            </HeaderLayout>
          }
        />
        <Route
          path="/iletisim"
          element={
            <HeaderLayout>
              <Header />
              <Contact />
              <Footer />
            </HeaderLayout>
          }
        />
      </Routes>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <DashboardProvider>
    <AppRoutes />
  </DashboardProvider>
);

import leagueBanner from "../assets/league-banner.png";
import listing from "../assets/listing.png";
import history from "../assets/history.png";

import MoreButton from "../components/MoreButton";
import Collapsible from "react-collapsible";
import FeatureSlider from "../components/FeatureSlider";

const AllLeagues = () => {
  return (
    <div className="mt-[100px]">
      <div className="bg-[url('./assets/all-leagues.png')] bg-cover flex flex-col sm:flex-row items-center justify-center gap-10 py-10 sm:py-20 px-4 sm:px-0">
        <div className="max-w-[520px]">
          <h3 className="text-4xl sm:text-7xl text-black font-medium mb-5">
            Tüm lig sonuçlarından haberdar olun
          </h3>
          <p className="text-lg font-normal text-black">
            Hiçbir detayı kaçırmadan sporun heyecanını kesintisiz yaşayın! Hangi
            ligde ya da turnuvada olursa olsun, tüm maçların sonuçları ve
            skorları tek bir ekranda.
          </p>
        </div>
        <div>
          <img src={leagueBanner} alt="fast" width={524} height={670} />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center gap-8 mt-24 sm:mt-52 mb-24 sm:mb-60 px-4 sm:px-0">
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
          <circle cx="30" cy="30" r="30" fill="#2C3EC4" />
          <path
            d="M31.5625 39.5906V42.8125H39.375V45.9375H20.625V42.8125H28.4375V39.5906C25.4167 39.2097 22.6388 37.7395 20.625 35.4559C18.6112 33.1722 17.5001 30.2322 17.5 27.1875V17.8125H42.5V27.1875C42.4999 30.2322 41.3888 33.1722 39.375 35.4559C37.3612 37.7395 34.5833 39.2097 31.5625 39.5906V39.5906ZM12.8125 20.9375H15.9375V27.1875H12.8125V20.9375ZM44.0625 20.9375H47.1875V27.1875H44.0625V20.9375Z"
            fill="white"
          />
        </svg>

        <p className="max-w-[762px] text-3xl sm:text-6xl font-medium text-center khand-medium">
          Tüm liglerin istatistikleri ve spor heyecanı için başvuracağınız yer!
        </p>
      </div>

      <div className="flex flex-col sm:flex-row items-center justify-center gap-16 sm:gap-32 px-4 sm:px-0">
        <img src={listing} alt="detail" width={260} height={565} />
        <div className="max-w-[500px] flex flex-col">
          <div className="py-6 border-y border-[#E1E1E7] relative">
            <div className="w-[200px] h-[1px] bg-[#F88939] absolute -top-0"></div>
            <p className="text-2xl font-medium mb-5">
              Lig bazlı içerik düzenlemesi
            </p>
            <p className="text-lg font-normal">
              Farklı liglere ait maç skorlarını ve sonuçlarını kolayca
              listeleyin ve takip edin.
            </p>
          </div>
          <p className="text-2xl font-medium py-6 border-b border-[#E1E1E7]">
            Ligler arası kolay geçiş
          </p>
          <p className="text-2xl font-medium border-b border-[#E1E1E7] py-6">
            Canlı lig güncellemeleri
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-24 sm:gap-52 mt-40 px-4 sm:px-0">
        <div className="flex flex-col sm:flex-row justify-center items-center gap-16 sm:gap-32">
          <div className="flex flex-col gap-5 max-w-[560px]">
            <p className="text-2xl sm:text-[42px] text-black font-medium">
              Kesintisiz skor güncellemeleri
            </p>
            <p className="text-lg text-black font-normal">
              Maç sonuçlarına anında, reklamsız erişim ile sadece sporun ve
              maçların keyfini çıkarın.
            </p>
          </div>
          <img src={history} alt="noAds" width={260} height={565} />
        </div>
      </div>

      <div className="flex flex-col gap-8 px-4 sm:px-[20vw] mt-40">
        <div className="flex flex-col sm:flex-row justify-between gap-6 sm:gap-0">
          <p className="text-3xl sm:text-6xl khand-medium">Yardım merkezi</p>
          <MoreButton text="Tümünü gör" link="/yardim-merkezi" />
        </div>

        <div className="flex flex-col gap-4 pb-32">
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama ücretsiz mi?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama için bir hesap oluşturmalı mıyım?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama ile ilgili bir sorun yaşarsam ne yapmalıyım?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
        </div>
      </div>

      <FeatureSlider />
    </div>
  );
};

export default AllLeagues;

import { useState } from "react";

import qr from "../assets/qr.png";
import promotion from "../assets/promotion.png";
import Collapsible from "react-collapsible";

const Help = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuTitle, setMenuTitle] = useState("");

  return (
    <div className="mt-[100px] relative">
      {menuOpen && (
        <div className="flex fixed top-0 left-0 right-0 bottom-0 z-[9999999999999]">
          <div
            onClick={() => setMenuOpen(false)}
            className="bg-black opacity-50 w-1/2 h-[100vh] hidden sm:block"
          ></div>
          <div className="bg-white w-full sm:w-1/2 h-[100vh] px-[6vw] flex flex-col overflow-scroll py-12">
            <div
              className="absolute scale-50 sm:scale-100 right-5 sm:right-[35px] top-[1px] sm:top-[35px] cursor-pointer"
              onClick={() => setMenuOpen(false)}
            >
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
                <circle
                  cx="30"
                  cy="30"
                  r="26.75"
                  stroke="black"
                  stroke-width="1.5"
                />
                <path
                  d="M30 27.7781L37.7781 20L40 22.2219L32.2219 30L40 37.7781L37.7781 40L30 32.2219L22.2219 40L20 37.7781L27.7781 30L20 22.2219L22.2219 20L30 27.7781Z"
                  fill="#09121F"
                />
              </svg>
            </div>
            <p className="text-black text-4xl font-normal mb-8">{menuTitle}</p>
            <div className="flex flex-col gap-4">
              <Collapsible
                trigger={
                  <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow ">
                    <span className="!text-2xl duration-300 group-hover:text-white font-normal">
                      Uygulama ücretsiz mi?
                    </span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                      <circle
                        cx="15.9993"
                        cy="15.9999"
                        r="13.9167"
                        transform="rotate(90 15.9993 15.9999)"
                        stroke="#2C3EC4"
                        stroke-width="1.5"
                      />
                      <path
                        d="M16.6863 18.7814L20.3644 15.2054L21.334 16.1481L16.0007 21.3334L10.6673 16.1481L11.6369 15.2054L15.315 18.7814L15.315 10.6667L16.6863 10.6667L16.6863 18.7814Z"
                        fill="#2C3EC4"
                      />
                    </svg>
                  </div>
                }
              >
                <p className="p-8 pt-4">
                  Evet, uygulamayı kullanmak için hesap oluşturmanız
                  gerekmektedir. Ancak, bazı özellikler için giriş
                  gerekmemektedir.
                </p>
              </Collapsible>
              <Collapsible
                trigger={
                  <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow ">
                    <span className="!text-2xl duration-300 group-hover:text-white font-normal">
                      Uygulama için bir hesap oluşturmalı mıyım?
                    </span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                      <circle
                        cx="15.9993"
                        cy="15.9999"
                        r="13.9167"
                        transform="rotate(90 15.9993 15.9999)"
                        stroke="#2C3EC4"
                        stroke-width="1.5"
                      />
                      <path
                        d="M16.6863 18.7814L20.3644 15.2054L21.334 16.1481L16.0007 21.3334L10.6673 16.1481L11.6369 15.2054L15.315 18.7814L15.315 10.6667L16.6863 10.6667L16.6863 18.7814Z"
                        fill="#2C3EC4"
                      />
                    </svg>
                  </div>
                }
              >
                <p className="p-8 pt-4">
                  Evet, uygulamayı kullanmak için hesap oluşturmanız
                  gerekmektedir. Ancak, bazı özellikler için giriş
                  gerekmemektedir.
                </p>
              </Collapsible>
              <Collapsible
                trigger={
                  <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow ">
                    <span className="!text-2xl duration-300 group-hover:text-white font-normal">
                      Uygulama ile ilgili bir sorun yaşarsam ne yapmalıyım?
                    </span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                      <circle
                        cx="15.9993"
                        cy="15.9999"
                        r="13.9167"
                        transform="rotate(90 15.9993 15.9999)"
                        stroke="#2C3EC4"
                        stroke-width="1.5"
                      />
                      <path
                        d="M16.6863 18.7814L20.3644 15.2054L21.334 16.1481L16.0007 21.3334L10.6673 16.1481L11.6369 15.2054L15.315 18.7814L15.315 10.6667L16.6863 10.6667L16.6863 18.7814Z"
                        fill="#2C3EC4"
                      />
                    </svg>
                  </div>
                }
              >
                <p className="p-8 pt-4">
                  Evet, uygulamayı kullanmak için hesap oluşturmanız
                  gerekmektedir. Ancak, bazı özellikler için giriş
                  gerekmemektedir.
                </p>
              </Collapsible>
              <Collapsible
                trigger={
                  <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow ">
                    <span className="!text-2xl duration-300 group-hover:text-white font-normal">
                      Canlı skorlar nasıl görüntülenir?
                    </span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                      <circle
                        cx="15.9993"
                        cy="15.9999"
                        r="13.9167"
                        transform="rotate(90 15.9993 15.9999)"
                        stroke="#2C3EC4"
                        stroke-width="1.5"
                      />
                      <path
                        d="M16.6863 18.7814L20.3644 15.2054L21.334 16.1481L16.0007 21.3334L10.6673 16.1481L11.6369 15.2054L15.315 18.7814L15.315 10.6667L16.6863 10.6667L16.6863 18.7814Z"
                        fill="#2C3EC4"
                      />
                    </svg>
                  </div>
                }
              >
                <p className="p-8 pt-4">
                  Evet, uygulamayı kullanmak için hesap oluşturmanız
                  gerekmektedir. Ancak, bazı özellikler için giriş
                  gerekmemektedir.
                </p>
              </Collapsible>
              <Collapsible
                trigger={
                  <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow ">
                    <span className="!text-2xl duration-300 group-hover:text-white font-normal">
                      Favori takımlarımı nasıl eklerim?
                    </span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                      <circle
                        cx="15.9993"
                        cy="15.9999"
                        r="13.9167"
                        transform="rotate(90 15.9993 15.9999)"
                        stroke="#2C3EC4"
                        stroke-width="1.5"
                      />
                      <path
                        d="M16.6863 18.7814L20.3644 15.2054L21.334 16.1481L16.0007 21.3334L10.6673 16.1481L11.6369 15.2054L15.315 18.7814L15.315 10.6667L16.6863 10.6667L16.6863 18.7814Z"
                        fill="#2C3EC4"
                      />
                    </svg>
                  </div>
                }
              >
                <p className="p-8 pt-4">
                  Evet, uygulamayı kullanmak için hesap oluşturmanız
                  gerekmektedir. Ancak, bazı özellikler için giriş
                  gerekmemektedir.
                </p>
              </Collapsible>
              <Collapsible
                trigger={
                  <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow ">
                    <span className="!text-2xl duration-300 group-hover:text-white font-normal">
                      Bildirimler nasıl ayarlanır?
                    </span>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                      <circle
                        cx="15.9993"
                        cy="15.9999"
                        r="13.9167"
                        transform="rotate(90 15.9993 15.9999)"
                        stroke="#2C3EC4"
                        stroke-width="1.5"
                      />
                      <path
                        d="M16.6863 18.7814L20.3644 15.2054L21.334 16.1481L16.0007 21.3334L10.6673 16.1481L11.6369 15.2054L15.315 18.7814L15.315 10.6667L16.6863 10.6667L16.6863 18.7814Z"
                        fill="#2C3EC4"
                      />
                    </svg>
                  </div>
                }
              >
                <p className="p-8 pt-4">
                  Evet, uygulamayı kullanmak için hesap oluşturmanız
                  gerekmektedir. Ancak, bazı özellikler için giriş
                  gerekmemektedir.
                </p>
              </Collapsible>
            </div>
          </div>
        </div>
      )}

      <div className="bg-[url('./assets/help-bg.png')] bg-cover flex items-center justify-center gap-10 py-16 flex-col sm:flex-row px-4 sm:px-0">
        <div className="max-w-[525px]">
          <p className="text-4xl sm:text-7xl text-white font-medium mb-4">
            Nasıl yardım edebiliriz?
          </p>
          <p className="text-lg font-normal text-white">
            Uygulamamızla ilgili sorularınızı yanıtlamak ve size yardımcı olmak
            için buradayız.
          </p>
        </div>

        <div className="flex flex-col gap-4 bg-white p-6 rounded-[32px] border shadow-custom w-full sm:w-max">
          <div className="flex flex-col sm:flex-row gap-4 justify-between">
            <input
              type="text"
              className="sm:w-[47%] px-4 py-3 bg-white rounded-3xl focus:border-[#2E40C5] duration-300"
              placeholder="Ad"
            />
            <input
              type="text"
              className="sm:w-[47%] px-4 py-3 bg-white rounded-3xl focus:border-[#2E40C5] duration-300"
              placeholder="Soyad"
            />
          </div>
          <div className="flex flex-col sm:flex-row gap-4 justify-between">
            <input
              type="text"
              className="sm:w-[47%] px-4 py-3 bg-white rounded-3xl focus:border-[#2E40C5] duration-300"
              placeholder="Telefon Numarası"
            />
            <input
              type="text"
              className="sm:w-[47%] px-4 py-3 bg-white rounded-3xl focus:border-[#2E40C5] duration-300"
              placeholder="E-Posta"
            />
          </div>

          <textarea
            type="text"
            className="sm:w-[94%] h-[150px] px-4 py-3 bg-white rounded-3xl focus:border-[#2E40C5] duration-300"
            placeholder="Mesajınız"
          />

          <div className="inline-flex items-center">
            <label
              className="relative flex items-center p-3 rounded-full cursor-pointer"
              htmlFor="link"
            >
              <input
                type="checkbox"
                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity  checked:bg-[#F88939] checked:before:bg-gray-900 hover:before:opacity-10"
                id="link"
              />
              <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                <svg
                  class="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="1"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </span>
            </label>
            <label
              className="mt-px font-light text-gray-700 cursor-pointer select-none"
              htmlFor="link"
            >
              <a
                href="/"
                class="flex flex-col sm:flex-row flex-nowrap font-sans text-xs sm:text-base antialiased font-medium leading-relaxed text-blue-gray-900"
              >
                <p className="underline text-nowrap">
                  Kişisel Verilerin Korunması Kanunu
                  <br className="sm:hidden" />
                </p>
                <p className="text-nowrap font-sans text-xs sm:text-base antialiased font-medium leading-relaxed text-blue-500 transition-colors hover:text-blue-700">
                  ’nu okudum, anladım.
                </p>
              </a>
            </label>
          </div>
          <a
            href="/"
            className="w-full flex bg-[#2C3EC4] py-4 rounded-3xl justify-center items-center text-white text-base duration-300 hover:bg-black"
          >
            Gönder
          </a>
        </div>
      </div>

      <div className="mt-32 px-4 sm:px-[17vw] mb-24">
        <h3 className="khand-medium text-4xl sm:text-6xl text-[#101820] mb-8">
          Yardım Merkezi
        </h3>
        <div className="flex flex-wrap gap-8">
          <div
            onClick={() => {
              setMenuOpen(true);
              setMenuTitle("Uygulama Hakkında");
            }}
            className="cursor-pointer duration-300 hover:shadow-custom p-8 max-w-[340px] border-2 border-[#E1E1E7] rounded-[32px]"
          >
            <svg width="90" height="90" viewBox="0 0 90 90" fill="none">
              <circle cx="45" cy="45" r="45" fill="#F5F5F5" />
              <path
                d="M30.9993 21.6667H58.9994C59.6182 21.6667 60.2117 21.9126 60.6493 22.3502C61.0869 22.7878 61.3327 23.3812 61.3327 24.0001V66.0001C61.3327 66.6189 61.0869 67.2124 60.6493 67.65C60.2117 68.0876 59.6182 68.3334 58.9994 68.3334H30.9993C30.3805 68.3334 29.787 68.0876 29.3494 67.65C28.9118 67.2124 28.666 66.6189 28.666 66.0001V24.0001C28.666 23.3812 28.9118 22.7878 29.3494 22.3502C29.787 21.9126 30.3805 21.6667 30.9993 21.6667ZM44.9994 56.6668C44.3805 56.6668 43.787 56.9126 43.3494 57.3502C42.9119 57.7878 42.666 58.3812 42.666 59.0001C42.666 59.6189 42.9119 60.2124 43.3494 60.65C43.787 61.0876 44.3805 61.3334 44.9994 61.3334C45.6182 61.3334 46.2117 61.0876 46.6493 60.65C47.0869 60.2124 47.3327 59.6189 47.3327 59.0001C47.3327 58.3812 47.0869 57.7878 46.6493 57.3502C46.2117 56.9126 45.6182 56.6668 44.9994 56.6668Z"
                fill="#2C3EC4"
              />
            </svg>
            <p className="text-3xl font-medium mt-8 mb-4">Uygulama hakkında</p>
            <p className="text-lg text-[#898989]">
              Uygulama hakkında sık sorulan sorular ve merak ettiklerinize
              ulaşabilirsiniz.
            </p>
          </div>
          <div
            onClick={() => {
              setMenuOpen(true);
              setMenuTitle("Uygulama kullanımı");
            }}
            className="cursor-pointer duration-300 hover:shadow-custom p-8 max-w-[340px] border-2 border-[#E1E1E7] rounded-[32px]"
          >
            <svg width="90" height="90" viewBox="0 0 90 90" fill="none">
              <circle cx="45" cy="45" r="45" fill="#F5F5F5" />
              <path
                d="M24 47.3333H42.6667V24H24V47.3333ZM24 66H42.6667V52H24V66ZM47.3333 66H66V42.6667H47.3333V66ZM47.3333 24V38H66V24H47.3333Z"
                fill="#2C3EC4"
              />
            </svg>

            <p className="text-3xl font-medium mt-8 mb-4">Uygulama kullanımı</p>
            <p className="text-lg text-[#898989]">
              Uygulamanın sunduğu temel özellikler hakkında bilgi
              edinebilirsiniz.
            </p>
          </div>
          <div
            onClick={() => {
              setMenuOpen(true);
              setMenuTitle("Hesap ve giriş");
            }}
            className="cursor-pointer duration-300 hover:shadow-custom p-8 max-w-[340px] border-2 border-[#E1E1E7] rounded-[32px]"
          >
            <svg width="90" height="90" viewBox="0 0 90 90" fill="none">
              <circle cx="45" cy="45" r="45" fill="#F5F5F5" />
              <path
                d="M49.6673 50.2546V68.3332H26.334C26.3333 65.484 26.9848 62.6724 28.2387 60.1138C29.4926 57.5553 31.3155 55.3178 33.5678 53.5727C35.8201 51.8276 38.4421 50.6212 41.2327 50.0461C44.0233 49.4709 46.9085 49.5423 49.6673 50.2546V50.2546ZM45.0007 47.3332C37.2657 47.3332 31.0007 41.0682 31.0007 33.3333C31.0007 25.5983 37.2657 19.3333 45.0007 19.3333C52.7357 19.3333 59.0006 25.5983 59.0006 33.3333C59.0006 41.0682 52.7357 47.3332 45.0007 47.3332ZM60.368 56.6666L56.1003 52.4012L59.402 49.0996L69.3 58.9999L59.402 68.9002L56.1003 65.5986L60.368 61.3332H52.0007V56.6666H60.368Z"
                fill="#2C3EC4"
              />
            </svg>

            <p className="text-3xl font-medium mt-8 mb-4">Hesap ve giriş</p>
            <p className="text-lg text-[#898989]">
              Hesabınız, giriş veya kayıt olma ile ilgili genel bilgilere ve
              sorun çözümlerine ulaşabilirsiniz.
            </p>
          </div>
          <div
            onClick={() => {
              setMenuOpen(true);
              setMenuTitle("Forum ve topluluk");
            }}
            className="cursor-pointer duration-300 hover:shadow-custom p-8 max-w-[340px] border-2 border-[#E1E1E7] rounded-[32px]"
          >
            <svg width="90" height="90" viewBox="0 0 90 90" fill="none">
              <circle cx="45" cy="45" r="45" fill="#F5F5F5" />
              <path
                d="M21.666 37.986C21.6648 36.1478 22.0262 34.3274 22.7295 32.6291C23.4328 30.9308 24.4642 29.3879 25.7646 28.0887C27.0651 26.7896 28.609 25.7597 30.3081 25.0581C32.0071 24.3565 33.8278 23.997 35.666 24H54.3327C62.063 24 68.3327 30.2884 68.3327 37.986V66H35.666C27.9357 66 21.666 59.7117 21.666 52.014V37.986ZM49.666 42.6667V47.3334H54.3327V42.6667H49.666ZM35.666 42.6667V47.3334H40.3327V42.6667H35.666Z"
                fill="#2C3EC4"
              />
            </svg>

            <p className="text-3xl font-medium mt-8 mb-4">Forum ve topluluk</p>
            <p className="text-lg text-[#898989]">
              Uygulamamız içerisinde yer alan forum ile ilgili kural ve merak
              ettikleriniz.
            </p>
          </div>
          <div
            onClick={() => {
              setMenuOpen(true);
              setMenuTitle("Güvenlik ve gizlilik");
            }}
            className="cursor-pointer duration-300 hover:shadow-custom p-8 max-w-[340px] border-2 border-[#E1E1E7] rounded-[32px]"
          >
            <svg width="90" height="90" viewBox="0 0 90 90" fill="none">
              <circle cx="45" cy="45" r="45" fill="#F5F5F5" />
              <path
                d="M45.0007 49.6666V68.3333H26.334C26.334 63.3825 28.3006 58.6346 31.8013 55.1339C35.302 51.6332 40.0499 49.6666 45.0007 49.6666V49.6666ZM45.0007 47.3333C37.2657 47.3333 31.0007 41.0683 31.0007 33.3333C31.0007 25.5983 37.2657 19.3333 45.0007 19.3333C52.7357 19.3333 59.0006 25.5983 59.0006 33.3333C59.0006 41.0683 52.7357 47.3333 45.0007 47.3333ZM66.0006 56.6666H68.334V68.3333H49.6673V56.6666H52.0007V54.3333C52.0007 52.4767 52.7381 50.6963 54.0509 49.3835C55.3637 48.0707 57.1441 47.3333 59.0006 47.3333C60.8572 47.3333 62.6376 48.0707 63.9504 49.3835C65.2632 50.6963 66.0006 52.4767 66.0006 54.3333V56.6666ZM61.334 56.6666V54.3333C61.334 53.7144 61.0882 53.1209 60.6506 52.6833C60.213 52.2458 59.6195 51.9999 59.0006 51.9999C58.3818 51.9999 57.7883 52.2458 57.3507 52.6833C56.9131 53.1209 56.6673 53.7144 56.6673 54.3333V56.6666H61.334Z"
                fill="#2C3EC4"
              />
            </svg>

            <p className="text-3xl font-medium mt-8 mb-4">
              Güvenlik ve gizlilik
            </p>
            <p className="text-lg text-[#898989]">
              Gizlilik politikamız ve veri koruma ile ilgili tüm sorularınızın
              cevaplarına ulaşabilirsiniz.
            </p>
          </div>
          <div
            onClick={() => {
              setMenuOpen(true);
              setMenuTitle("İletişim ve destek");
            }}
            className="cursor-pointer duration-300 hover:shadow-custom p-8 max-w-[340px] border-2 border-[#E1E1E7] rounded-[32px]"
          >
            <svg width="90" height="90" viewBox="0 0 90 90" fill="none">
              <circle cx="45" cy="45" r="45" fill="#F5F5F5" />
              <path
                d="M40.3125 23.9062H49.6875C54.6603 23.9063 59.4294 25.8817 62.9458 29.398C66.4621 32.9143 68.4375 37.6834 68.4375 42.6562C68.4375 47.6291 66.4621 52.3982 62.9458 55.9145C59.4294 59.4308 54.6603 61.4062 49.6875 61.4062V69.6094C37.9688 64.9219 21.5625 57.8906 21.5625 42.6562C21.5625 37.6834 23.5379 32.9143 27.0542 29.398C30.5706 25.8817 35.3397 23.9063 40.3125 23.9062V23.9062Z"
                fill="#2C3EC4"
              />
            </svg>

            <p className="text-3xl font-medium mt-8 mb-4">İletişim ve destek</p>
            <p className="text-lg text-[#898989]">
              Uygulamamız hakkında genel destek ve iletişim ile ilgili
              sorularınız için bilgiler.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-8 px-4 sm:px-[20vw] mt-40">
        <div className="flex flex-col sm:flex-row gap-8 sm:gap-0 justify-between">
          <p className="text-3xl sm:text-6xl khand-medium">Popüler Sorular</p>
          <p className="text-lg max-w-[600px]">
            Bu SSS bölümü, kullanıcıların en sık karşılaştıkları sorunları ve
            soruları hızlıca çözmelerine yardımcı olur ve uygulamanızın
            kullanımını daha sorunsuz hale getirir.
          </p>
        </div>

        <div className="flex flex-col gap-4 pb-32">
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama ücretsiz mi?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama için bir hesap oluşturmalı mıyım?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama ile ilgili bir sorun yaşarsam ne yapmalıyım?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
        </div>
      </div>

      <div className="py-8 sm:py-0 sm:pt-44 sm:pl-60 flex flex-col sm:flex-row gap-8 bg-[rgba(44, 62, 196, 0.10)] px-4 sm:p-0 help-bg">
        <div className="flex flex-col gap-8 max-w-[445px]">
          <div className="flex flex-col gap-4">
            <p className="text-[#101820] text-2xl sm:text-4xl font-medium">
              Maçkanalı mobil uygulamasını indirin.
            </p>
            <p className="text-[#101820] text-lg font-normal">
              Sporun ve maçların heyecanını parmaklarınızın ucuna getirin!
            </p>
          </div>
          <div className="flex gap-4">
            <a
              href="/"
              className="flex items-center justify-center gap-1 w-[130px] h-[50px] rounded-[500px] text-[#fff] bg-[#2C3EC4] duration-300 hover:bg-black"
            >
              <p className="font-semibold">İndir</p>

              <svg width="12" height="13" viewBox="0 0 12 13" fill="none">
                <path
                  d="M0 11.6316H12V13H0V11.6316ZM6.66667 7.644L10.714 3.48947L11.6567 4.45695L6 10.2632L0.343333 4.45763L1.286 3.48947L5.33333 7.64263V0H6.66667V7.644Z"
                  fill="white"
                />
              </svg>
            </a>
            <a
              href="http://mobile.mackanali.com/login"
              className="flex items-center justify-center gap-1 w-[130px] h-[50px] rounded-[500px] text-[#2C3EC4] bg-[#fff] border border-[#2C3EC4] duration-300 hover:opacity-50"
            >
              <p className="font-semibold">MaçWeb</p>
            </a>
          </div>
          <div className="flex flex-col sm:flex-row items-center sm:items-start gap-8 p-8 bg-white border border-[#E1E1E7] rounded-xl">
            <img src={qr} width={108} height={108} alt="QR code" />
            <div>
              <p className="text-lg font-normal text-[#101820] mb-4 text-center sm:text-start">
                QR kodu okutarak cihazınıza Maçkanalı uygulamasını indirin!
              </p>
              <div className="flex items-center gap-8 justify-center sm:justify-start">
                <svg width="32" height="37" viewBox="0 0 32 37" fill="none">
                  <path
                    d="M0.61745 1.35145C0.17489 1.89225 -0.0429218 2.57854 0.00702229 3.27307V33.6346C-0.0401472 34.3346 0.194313 35.0264 0.65907 35.5562L0.756184 35.666L17.9037 18.6597V18.2479L0.714564 1.25537L0.61745 1.35145Z"
                    fill="url(#paint0_linear_102_5072)"
                  />
                  <path
                    d="M23.5943 24.3279L17.9062 18.6592V18.2474L23.5943 12.5786L23.7192 12.661L30.531 16.4767C32.4732 17.5611 32.4732 19.3454 30.531 20.4435L23.7469 24.2593L23.5943 24.3279Z"
                    fill="url(#paint1_linear_102_5072)"
                  />
                  <path
                    d="M23.7583 24.2643L17.9038 18.4583L0.617676 35.5608C1.42649 36.2732 2.63347 36.313 3.48945 35.6569L23.7583 24.2643Z"
                    fill="url(#paint2_linear_102_5072)"
                  />
                  <path
                    d="M23.7583 12.6624L3.48945 1.27001C2.63902 0.604306 1.42788 0.63862 0.617676 1.35236L17.9038 18.4547L23.7583 12.6624Z"
                    fill="url(#paint3_linear_102_5072)"
                  />
                  <path
                    opacity="0.2"
                    d="M23.5919 24.1265L3.48945 35.4502C2.66815 36.0637 1.53609 36.0637 0.714789 35.4502L0.617676 35.5462L0.714789 35.656C1.53609 36.2696 2.66815 36.2696 3.48945 35.656L23.7583 24.2637L23.5919 24.1265Z"
                    fill="black"
                  />
                  <path
                    opacity="0.12"
                    d="M0.618507 35.3686C0.173173 34.8237 -0.0460262 34.1319 0.00807985 33.4332V33.6391C-0.0390895 34.3392 0.19537 35.0309 0.660127 35.5608L0.75724 35.4647L0.618507 35.3686ZM30.5294 20.2426L23.5928 24.1271L23.7176 24.2643L30.5294 20.4348C31.348 20.0916 31.9126 19.334 32 18.4583C31.8238 19.2502 31.2786 19.9132 30.5294 20.2426Z"
                    fill="black"
                  />
                  <path
                    opacity="0.25"
                    d="M3.48879 1.46544L30.5279 16.6737C31.277 17.0031 31.8222 17.666 31.9984 18.458C31.911 17.5823 31.3464 16.8246 30.5279 16.4815L3.48879 1.27328C1.5604 0.175217 0.0065918 1.08112 0.0065918 3.27725V3.48314C0.0065918 1.28701 1.5604 0.381104 3.48879 1.46544Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_102_5072"
                      x1="10.9194"
                      y1="2.95732"
                      x2="-3.64716"
                      y2="17.6807"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#00A0FF" />
                      <stop offset="0.01" stop-color="#00A1FF" />
                      <stop offset="0.26" stop-color="#00BEFF" />
                      <stop offset="0.51" stop-color="#00D2FF" />
                      <stop offset="0.76" stop-color="#00DFFF" />
                      <stop offset="1" stop-color="#00E3FF" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_102_5072"
                      x1="33.0698"
                      y1="18.4533"
                      x2="-0.489689"
                      y2="18.4533"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FFE000" />
                      <stop offset="0.41" stop-color="#FFBD00" />
                      <stop offset="0.78" stop-color="#FFA500" />
                      <stop offset="1" stop-color="#FF9C00" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_102_5072"
                      x1="20.5814"
                      y1="23.912"
                      x2="-4.33921"
                      y2="49.0892"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FF3A44" />
                      <stop offset="1" stop-color="#C31162" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_102_5072"
                      x1="-3.73856"
                      y1="-1.31055"
                      x2="7.38302"
                      y2="9.93056"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#32A071" />
                      <stop offset="0.07" stop-color="#2DA771" />
                      <stop offset="0.48" stop-color="#15CF74" />
                      <stop offset="0.8" stop-color="#06E775" />
                      <stop offset="1" stop-color="#00F076" />
                    </linearGradient>
                  </defs>
                </svg>
                <svg width="32" height="39" viewBox="0 0 32 39" fill="none">
                  <path
                    d="M23.7755 9.4191C26.6588 9.50846 29.3303 10.9365 30.9839 13.2754C28.3732 14.8633 26.7665 17.6627 26.7283 20.6942C26.7318 24.1243 28.8075 27.221 32 28.5563C31.3869 30.5309 30.461 32.3954 29.2573 34.083C27.642 36.4734 25.9484 38.8089 23.2596 38.8536C20.6472 38.912 19.77 37.3327 16.7738 37.3327C13.7514 37.3327 12.8135 38.8106 10.3088 38.9137C7.7485 39.0065 5.79267 36.36 4.11824 33.9919C0.771108 29.1561 -1.83434 20.3608 1.6587 14.377C3.29839 11.4607 6.36587 9.60985 9.73732 9.50159C12.3011 9.45003 14.6807 11.2149 16.2561 11.2149C17.802 11.2149 20.7427 9.10461 23.7755 9.4191ZM23.8067 0C23.9804 2.25983 23.2596 4.49731 21.7936 6.23816C20.371 7.98759 18.2154 8.99635 15.947 8.97229C15.8028 6.77776 16.5462 4.61761 18.014 2.96613C19.4991 1.29231 21.5678 0.233716 23.8067 0Z"
                    fill="black"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <img src={promotion} alt="promotion" />
      </div>
    </div>
  );
};

export default Help;

import { gsap } from "gsap";
import { useEffect, useRef, useContext } from "react";
import { DasboardContext } from "../context/context";

const Banner = () => {
  const phone = useRef(null);
  const ball = useRef(null);
  const player = useRef(null);

  const { formShow, setFormShow } = useContext(DasboardContext);

  useEffect(() => {
    gsap.to(phone.current, {
      y: 0,
      duration: 1,
    });

    gsap.to(ball.current, {
      opacity: 1,
      y: 0,
      duration: 1,
      delay: 1,
    });

    gsap.to(player.current, {
      opacity: 1,
      x: 0,
      duration: 1,
      delay: 1.4,
    });
  }, []);

  return (
    <div className="pt-20 sm:pt-0 sm:pr-8 sm:ml-8 relative sm:mt-[100px] overflow-hidden px-4 sm:px-0 z-0">
      <div className="absolute w-[50%] h-[80%] top-12 opacity-50 bg-black blur-[50px] -z-0 left-12 rounded-[32px] hidden sm:block"></div>
      <div className="bg-[url('./assets/banner.png')] bg-cover rounded-[32px] flex flex-col-reverse items-center justify-center sm:gap-64 sm:flex-row p-7 sm:p-0">
        <div className="max-w-96 relative z-[5]">
          <p className="text-white sm:text-7xl text-4xl font-medium mb-4">
            Spordaki hiçbir anı kaçırmayın!
          </p>
          <p className="text-white text-lg font-normal mb-8">
            Tüm liglerin spor istatistikleri ve spor heyecanı için
            başvuracağınız yer!
          </p>
          <div className="flex gap-4">
            <a
              href="https://mackanali.com/mackanali.apk"
              className="flex items-center justify-center gap-1 w-[130px] h-[50px] rounded-[500px] text-[#fff] bg-[#2C3EC4]  border-[#2C3EC4] duration-300 hover:bg-black"
            >
              <p>İndir</p>

              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                className="duration-300 hover:invert"
              >
                <path
                  d="M0 11.6316H12V13H0V11.6316ZM6.66667 7.644L10.714 3.48947L11.6567 4.45695L6 10.2632L0.343333 4.45763L1.286 3.48947L5.33333 7.64263V0H6.66667V7.644Z"
                  fill="white"
                />
              </svg>
            </a>
            <div
              // onClick={() => setFormShow(true)}
              className="cursor-pointer flex items-center justify-center gap-1 w-[130px] h-[50px] rounded-[500px] text-black sm:text-[#fff] border border-[#fff] duration-300 hover:opacity-50 bg-white sm:bg-transparent"
            >
              <a href="http://mobile.mackanali.com/login">
              MaçWeb
              </a>
            </div>
          </div>
        </div>
        <div
          className="bg-[url('./assets/player.png')] z-[4] w-[350px] h-[250px] sm:w-[570px] sm:h-[355px] bg-cover absolute opacity-0 -translate-x-16 bg-center -left-24 sm:left-[initial] top-[430px] sm:top-[initial]"
          ref={player}
        ></div>
        <div
          className="bg-[url('./assets/mobile.png')] w-[325px] h-[647px] bg-cover relative translate-y-8 bg-center"
          ref={phone}
        >
          <div
            className="bg-[url('./assets/ball.png')] w-[150px] h-[175px] sm:w-[200px] sm:h-[252px] bg-cover absolute bottom-[10%] sm:bottom-0 sm:top-[40%] sm:right-[-20%] right-[-30px] opacity-0 translate-y-12 bg-center"
            ref={ball}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Banner;

import { useRef } from "react";

import privacy from "../assets/privacy.png";
import p1 from "../assets/pri1.png";
import p2 from "../assets/pri2.png";

import MoreButton from "../components/MoreButton";
import Collapsible from "react-collapsible";

import slider1 from "../assets/slider-1.png";
import slider2 from "../assets/slider-2.png";
import slider3 from "../assets/slider-3.png";
import slider4 from "../assets/slider-4.png";

import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import FeatureSlider from "../components/FeatureSlider";

const Privacy = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <div className="mt-[95px] mobile-bg">
      <div className="flex flex-col sm:flex-row px-4 sm:px-0 items-center justify-center gap-10 py-20">
        <div className="max-w-[520px]">
          <h3 className="text-4xl sm:text-7xl text-black font-medium mb-5">
            Gizlilik politikamızla güvence
          </h3>
          <p className="text-lg font-normal text-black">
            Kullanıcı bilgilerinizi ve yaptığınız işlemleri şifreleme
            teknolojileriyle güvence altına alıyoruz.
          </p>
        </div>
        <div>
          <img src={privacy} alt="fast" width={524} height={670} />
        </div>
      </div>

      <div className="flex flex-col gap-20 justify-center items-center sm:mt-48 px-4 sm:px-0">
        <p className="khand-medium text-3xl sm:text-6xl text-black  max-w-[760px] text-center">
          <span className="text-[#2C3EC4]">
            Gizliliğiniz bizim önceliğimiz:
          </span>
          Kişisel verileriniz ve giriş bilgileriniz güvende!
        </p>
        <div className="flex flex-col sm:flex-row justify-center items-center gap-12 sm:gap-8">
          <div className="flex flex-col gap-4 max-w-96 items-center justify-center">
            <svg width="29" height="36" viewBox="0 0 29 36" fill="none">
              <path
                d="M1.48975 3.47442L14.5 0.583252L27.5103 3.47442C27.8618 3.55258 28.1763 3.74828 28.4017 4.02923C28.6271 4.31017 28.7499 4.65957 28.75 5.01975V20.8325C28.7499 22.3964 28.3637 23.9361 27.6257 25.3149C26.8876 26.6938 25.8206 27.869 24.5193 28.7365L14.5 35.4166L4.48067 28.7365C3.1796 27.8692 2.11273 26.6942 1.37472 25.3156C0.636706 23.9371 0.250374 22.3977 0.25 20.8341V5.01975C0.250062 4.65957 0.372927 4.31017 0.598317 4.02923C0.823707 3.74828 1.13815 3.55258 1.48975 3.47442Z"
                fill="#F88939"
              />
            </svg>
            <h3 className="text-2xl font-medium text-center">
              Veri koruma ve izleme
            </h3>
            <p className="text-lg font-normal text-center">
              Kişisel bilgilerinizi üçüncü taraflarla paylaşmıyoruz; verileriniz
              uygulama içi güvenlik ve iyileştirme amaçlı kullanılır.
            </p>
          </div>
          <div className="flex flex-col gap-4 max-w-96 items-center justify-center">
            <svg width="29" height="34" viewBox="0 0 29 34" fill="none">
              <path
                d="M24 11.6666H27.1667C27.5866 11.6666 27.9893 11.8334 28.2863 12.1303C28.5832 12.4273 28.75 12.83 28.75 13.2499V32.2499C28.75 32.6698 28.5832 33.0726 28.2863 33.3695C27.9893 33.6664 27.5866 33.8333 27.1667 33.8333H1.83333C1.41341 33.8333 1.01068 33.6664 0.713748 33.3695C0.416815 33.0726 0.25 32.6698 0.25 32.2499V13.2499C0.25 12.83 0.416815 12.4273 0.713748 12.1303C1.01068 11.8334 1.41341 11.6666 1.83333 11.6666H5V10.0833C5 7.5637 6.00089 5.14733 7.78249 3.36574C9.56408 1.58414 11.9804 0.583252 14.5 0.583252C17.0196 0.583252 19.4359 1.58414 21.2175 3.36574C22.9991 5.14733 24 7.5637 24 10.0833V11.6666ZM20.8333 11.6666V10.0833C20.8333 8.40355 20.1661 6.79264 18.9783 5.60491C17.7906 4.41718 16.1797 3.74992 14.5 3.74992C12.8203 3.74992 11.2094 4.41718 10.0217 5.60491C8.83393 6.79264 8.16667 8.40355 8.16667 10.0833V11.6666H20.8333ZM6.58333 16.4166V19.5833H9.75V16.4166H6.58333ZM6.58333 21.1666V24.3333H9.75V21.1666H6.58333ZM6.58333 25.9166V29.0833H9.75V25.9166H6.58333Z"
                fill="#F88939"
              />
            </svg>

            <h3 className="text-2xl font-medium text-center">
              Şifreli veri koruma
            </h3>
            <p className="text-lg font-normal text-center">
              Kişisel bilgilerinizi ve uygulama içindeki işlemlerinizi güçlü
              şifreleme teknolojileriyle güvence altına alıyoruz.
            </p>
          </div>
          <div className="flex flex-col gap-4 max-w-96 items-center justify-center">
            <svg width="30" height="34" viewBox="0 0 30 34" fill="none">
              <path
                d="M13.5007 21.1666V33.8333H0.833984C0.833984 30.4738 2.1685 27.252 4.54397 24.8766C6.91943 22.5011 10.1412 21.1666 13.5007 21.1666ZM13.5007 19.5833C8.2519 19.5833 4.00065 15.332 4.00065 10.0833C4.00065 4.8345 8.2519 0.583252 13.5007 0.583252C18.7494 0.583252 23.0007 4.8345 23.0007 10.0833C23.0007 15.332 18.7494 19.5833 13.5007 19.5833ZM27.7507 25.9166H29.334V33.8333H16.6673V25.9166H18.2507V24.3333C18.2507 23.0735 18.7511 21.8653 19.6419 20.9745C20.5327 20.0837 21.7409 19.5833 23.0007 19.5833C24.2604 19.5833 25.4686 20.0837 26.3594 20.9745C27.2502 21.8653 27.7507 23.0735 27.7507 24.3333V25.9166ZM24.584 25.9166V24.3333C24.584 23.9133 24.4172 23.5106 24.1202 23.2137C23.8233 22.9167 23.4206 22.7499 23.0007 22.7499C22.5807 22.7499 22.178 22.9167 21.8811 23.2137C21.5841 23.5106 21.4173 23.9133 21.4173 24.3333V25.9166H24.584Z"
                fill="#F88939"
              />
            </svg>

            <h3 className="text-2xl font-medium text-center">
              Güvenli giriş ve kişisel verileriniz
            </h3>
            <p className="text-lg font-normal text-center">
              Uygulama içindeki güvenlik açıklarını izliyor ve verilerinizi her
              zaman koruma altında tutuyoruz.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-32 sm:gap-52 mt-40">
        <div className="flex justify-center items-center gap-10 sm:gap-32 flex-col sm:flex-row px-4 sm:px-0">
          <img src={p1} alt="noAds" width={260} height={565} />
          <div className="flex flex-col gap-5 max-w-[560px]">
            <p className="text-2xl sm:text-[42px] text-black font-medium">
              Güvenli oturum açma ve kimlik doğrulama
            </p>
            <p className="text-lg text-black font-normal">
              Güçlü kimlik doğrulama yöntemleri ve güvenli oturum açma süreçleri
              ile verilerinizi koruruz.
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center gap-10 sm:gap-32 flex-col-reverse sm:flex-row px-4 sm:px-0">
          <div className="flex flex-col gap-5 max-w-[560px]">
            <p className="text-2xl sm:text-[42px] text-black font-medium">
              Üçüncü taraflarla veri paylaşımı
            </p>
            <p className="text-lg text-black font-normal">
              Forumdaki bilgileriniz ve kişisel verileriniz, üçüncü taraflarla
              paylaşılmaz ve yalnızca uygulama içi güvenlik amacıyla kullanılır.
            </p>
          </div>
          <img src={p2} alt="noAds" width={260} height={565} />
        </div>
      </div>

      <div className="flex flex-col gap-8 px-4 sm:px-[20vw] mt-40">
        <div className="flex flex-col sm:flex-row justify-between gap-6 sm:gap-0">
          <p className="text-3xl sm:text-6xl khand-medium">Yardım merkezi</p>
          <MoreButton text="Tümünü gör" link="/yardim-merkezi" />
        </div>

        <div className="flex flex-col gap-4 pb-32">
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama ücretsiz mi?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama için bir hesap oluşturmalı mıyım?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="flex items-center justify-between p-8 rounded-2xl duration-300 border border-[#E1E1E7] hover:bg-[#F88939] group special-shadow bg-white">
                <span className="max-w-[200px] sm:max-w-full !text-lg sm:!text-2xl duration-300 group-hover:text-white">
                  Uygulama ile ilgili bir sorun yaşarsam ne yapmalıyım?
                </span>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
                  <circle
                    cx="15.0003"
                    cy="15"
                    r="13.9167"
                    transform="rotate(90 15.0003 15)"
                    fill="white"
                    stroke="white"
                    stroke-width="1.5"
                  />
                  <path
                    d="M15.6854 17.7814L19.3634 14.2054L20.333 15.148L14.9997 20.3334L9.66634 15.148L10.6359 14.2054L14.314 17.7814L14.314 9.66669L15.6854 9.66669L15.6854 17.7814Z"
                    fill="black"
                  />
                </svg>
              </div>
            }
          >
            <p className="p-8 pt-4">
              Evet, uygulamayı kullanmak için hesap oluşturmanız gerekmektedir.
              Ancak, bazı özellikler için giriş gerekmemektedir.
            </p>
          </Collapsible>
        </div>
      </div>

      <FeatureSlider />
    </div>
  );
};

export default Privacy;

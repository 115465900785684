import { createContext, useState } from "react";

const DasboardContext = createContext();

const DashboardProvider = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [formShow, setFormShow] = useState(false);

  return (
    <DasboardContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        show,
        setShow,
        formShow,
        setFormShow,
      }}
    >
      {children}
    </DasboardContext.Provider>
  );
};

export { DasboardContext, DashboardProvider };

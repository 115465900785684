import { useContext } from "react";
import { DasboardContext } from "../context/context";

const HeaderLayout = ({ children }) => {
  const { menuOpen, show, formShow } = useContext(DasboardContext);
  return (
    <div
      className={`${menuOpen ? "fixed" : "relative"} ${
        formShow ? "fixed" : "relative"
      }`}
    >
      {children}
    </div>
  );
};

export default HeaderLayout;

import { useEffect, useRef } from "react";
import gsap from "gsap";

import MoreButton from "./MoreButton";

const Infos = () => {
  const container1 = useRef(null);
  const container2 = useRef(null);
  const container3 = useRef(null);
  const text1 = useRef(null);
  const text2 = useRef(null);
  const text3 = useRef(null);
  const phone1 = useRef(null);
  const phone2 = useRef(null);
  const phone3 = useRef(null);

  useEffect(() => {
    gsap.to(text1.current, {
      y: 0,
      opacity: 1,
      duration: 1,
      delay: 1,
      scrollTrigger: {
        trigger: container1.current,
        start: "top center",
        end: "top 100px",
      },
    });
    gsap.to(phone1.current, {
      y: 0,
      opacity: 1,
      duration: 1,
      scrollTrigger: {
        trigger: container1.current,
        start: "top center",
        end: "top 100px",
      },
    });

    gsap.to(text2.current, {
      y: 0,
      opacity: 1,
      duration: 1,
      delay: 1,
      scrollTrigger: {
        trigger: container2.current,
        start: "top center",
        end: "top 100px",
      },
    });
    gsap.to(phone2.current, {
      y: 0,
      opacity: 1,
      duration: 1,
      scrollTrigger: {
        trigger: container2.current,
        start: "top center",
        end: "top 100px",
      },
    });

    gsap.to(text3.current, {
      y: 0,
      opacity: 1,
      duration: 1,
      delay: 1,
      scrollTrigger: {
        trigger: container3.current,
        start: "top center",
        end: "top 100px",
      },
    });
    gsap.to(phone3.current, {
      y: 0,
      opacity: 1,
      duration: 1,
      scrollTrigger: {
        trigger: container3.current,
        start: "top center",
        end: "top 100px",
      },
    });
  }, []);
  return (
    <div className="mt-16 sm:mt-32 flex flex-col gap-20 sm:gap-40 pb-32 sm:pb-40">
      <div
        className="flex flex-col sm:flex-row items-center justify-center gap-8 sm:gap-44"
        ref={container1}
      >
        <div
          className="bg-[url('./assets/info-1.png')] w-[240px] sm:w-[360px] h-[450px] sm:h-[700px] bg-cover opacity-0  translate-y-40 bg-center"
          ref={phone1}
        ></div>
        <div
          className="flex flex-col max-w-md opacity-0  translate-y-40 p-4 sm:p-0"
          ref={text1}
        >
          <p className="khand-medium text-3xl sm:text-6xl text-black">
            <span className="text-[#2C3EC4]">Tüm skorlar ve sonuçlar</span>{" "}
            anında ekranınızda
          </p>
          <p className="sm:text-lg text-black font-normal mt-5 mb-8">
            Futbol, basketbol, voleybol, tenis ve buz hokeyine kadar, 5000'den
            fazla lig ve turnuva sonuçlarını ilk siz öğrenin. Maçkanalı
            uygulamasıyla hiçbir şeyi kaçırmayın! ⚽🏀🎾🏐
          </p>
          <MoreButton link="/hizli-bildirimler" />
        </div>
      </div>
      <div
        className="flex sm:flex-row items-center justify-center gap-8 sm:gap-44 flex-col-reverse"
        ref={container2}
      >
        <div
          className="flex flex-col max-w-md opacity-0  translate-y-40 p-4 sm:p-0"
          ref={text2}
        >
          <p className="khand-medium text-3xl sm:text-6xl text-black">
            Maç heyecanını herkes ile
            <span className="text-[#2C3EC4]"> paylaşın</span>
          </p>
          <p className="sm:text-lg text-black font-normal mt-5 mb-8">
            Detaylı istatistikler ve analizler ile sohbetlerinizi zenginleştirin
            ve sporun coşkusunu birlikte yaşayın.
          </p>
          <MoreButton link="/yorum-yapin" />
        </div>
        <div
          className="bg-[url('./assets/info-1.png')] w-[240px] sm:w-[360px] h-[450px] sm:h-[700px] bg-cover opacity-0  translate-y-40 bg-center"
          ref={phone2}
        ></div>
      </div>
      <div
        className="flex flex-col sm:flex-row items-center justify-center gap-8 sm:gap-44"
        ref={container3}
      >
        <div
          className="bg-[url('./assets/info-1.png')] w-[240px] sm:w-[360px] h-[450px] sm:h-[700px] bg-cover opacity-0  translate-y-40 bg-center"
          ref={phone3}
        ></div>
        <div
          className="flex flex-col max-w-md opacity-0  translate-y-40 p-4 sm:p-0"
          ref={text3}
        >
          <p className="khand-medium text-3xl sm:text-6xl text-black">
            Tüm
            <span className="text-[#2C3EC4]"> lig sonuçlarından</span> haberdar
            olun
          </p>
          <p className="sm:text-lg text-black font-normal mt-5 mb-8">
            Hiçbir detayı kaçırmadan sporun heyecanını kesintisiz yaşayın! Hangi
            ligde ya da turnuvada olursa olsun, tüm maçların sonuçları ve
            skorları tek bir ekranda.
          </p>
          <MoreButton link="/tum-ligler" />
        </div>
      </div>
    </div>
  );
};

export default Infos;
